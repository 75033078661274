import React, { useState } from "react";

import { useDispatch } from "react-redux";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { userActions } from "../_actions";
import { alertActions } from "../_actions";

import { alertConstants } from "../_constants";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    submitted: false,
  });

  const { email, password } = formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormData({ ...formData, submitted: true });

    if (email && password) {
      dispatch(userActions.login(email, password));
    } else {
      dispatch(
        alertActions.setAlert(
          "Les champs doivent être remplis.",
          alertConstants.ERROR
        )
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="loginForm">
      <Form.Row>
        <Col md={8}>
          <Form.Group
            as={Col}
            controlId="loginEmail"
            className="loginForm__group"
          >
            <Form.Label className="loginForm__label">Adresse email</Form.Label>
            <Form.Control
              type="email"
              placeholder={`${t("Your email")}`}
              required
              name="email"
              value={email}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group
            as={Col}
            controlId="loginPassword"
            className="loginForm__group"
          >
            <Form.Label className="loginForm__label">Mot de passe</Form.Label>
            <Form.Control
              type="password"
              placeholder={`${t("Your password")}`}
              required
              name="password"
              value={password}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        <Col md={4} className="d-flex flex-column">
          <Button
            variant="primary"
            type="submit"
            className="loginForm__submit mb-2"
          >
            {t("Sign in")}
          </Button>
          <Link to="/forgot-password">{i18n.t("Forgot password ?")}</Link>
        </Col>
      </Form.Row>
    </Form>
  );
};
