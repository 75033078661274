import { ApiService } from "./api";

const apiService = new ApiService();
apiService.setApiUrl("/library_docs");

export const libraryDocsService = {
  findAll,
  find,
  add,
  remove,
};

async function add(item) {
  return apiService.add(item);
}

async function findAll(page = 1) {
  return apiService.findAll(page);
}

async function find(id) {
  return apiService.find(id);
}

async function remove(id) {
  return apiService.remove(id);
}
