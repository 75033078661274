import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Link, useLocation } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

/* Utils */
import { userActions } from "../../_actions";

import "../private/PrivateHeader.scss";
import "./AdminHeader.scss";

import ecpyLogo from "../ecpy.png";

import frFlag from "../../_components/fr.svg";
import gbFlag from "../../_components/gb.svg";

const AdminHeader = ({ dispatch, auth }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const askLogout = () => {
    dispatch(userActions.logout());
  };

  const switchLang = (lang) => {
    localStorage.lang = lang;
    i18n.changeLanguage(lang);
  };

  return (
    <header className="ecpy-header">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "absolute",
          top: "25px",
          left: "25px",
        }}
      >
        <img
          src={ecpyLogo}
          style={{
            width: "60px",
          }}
          alt="ECPY logo"
        />

        <div
          style={{
            borderRight: "2px solid #FFFFFF",
            height: "80px",
            margin: "0 25px",
          }}
        ></div>

        <h1 className="ecpy-header__title">
          {t("ECPY TRANSPORT AND CHARTER AGREEMENTS PLATFORM")}
        </h1>
      </div>

      <Navbar variant="dark" expand="lg">
        <Navbar.Toggle aria-controls="admin-navbar-nav" />

        <Navbar.Collapse id="admin-navbar-nav">
          <Nav>
            <Nav.Link
              as={Link}
              to="/admin"
              className={location.pathname === "/admin" ? "active" : ""}
            >
              {t("Dashboard")}
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/admin/companies"
              className={
                location.pathname.startsWith("/admin/companies") ? "active" : ""
              }
            >
              {t("Companies")}
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/admin/brokers"
              className={
                location.pathname.startsWith("/admin/brokers") ? "active" : ""
              }
            >
              {t("Brokers")}
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/admin/library"
              className={
                location.pathname.startsWith("/admin/library") ? "active" : ""
              }
            >
              {t("Documents")}
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/admin/contracts"
              className={
                location.pathname.startsWith("/admin/contracts") ? "active" : ""
              }
            >
              {t("Contracts")}
            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/admin/stats"
              className={
                location.pathname.startsWith("/admin/stats") ? "active" : ""
              }
            >
              {t("Statistics")}
            </Nav.Link>
          </Nav>

          <Nav
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
          >
            <div className="flags-wrapper">
              <img
                src={frFlag}
                className="flagIcon"
                onClick={() => switchLang("fr")}
                alt="FR"
              />
              <img
                src={gbFlag}
                className="flagIcon"
                onClick={() => switchLang("en")}
                alt="EN"
              />
            </div>

            <Button
              onClick={askLogout}
              variant="outline-light"
              style={{ marginTop: "10px" }}
            >
              {t("Logout")}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authentication,
});

const headerConnected = connect(mapStateToProps)(AdminHeader);
export { headerConnected as AdminHeader };
