import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import { Row, Col, Button } from "react-bootstrap";

import {
  brokersService,
  companiesService,
  contractHistoryService,
} from "../../_services";

import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";

import { StatsCard } from "../../_components";

export const StatsIndex = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [brokersCount, setBrokersCount] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);

  const [contractsCount, setContractsCount] = useState(0);
  const [mediumYachtLength, setMediumYachtLength] = useState(0);

  useEffect(() => {
    const fetchStats = async () => {
      // Brokers
      try {
        const res = await brokersService.findAll();
        setBrokersCount(res["hydra:totalItems"]);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      // Companies
      try {
        const res = await companiesService.findAll();
        setCompaniesCount(res["hydra:totalItems"]);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      // Contracts
      try {
        const res = await contractHistoryService.findAll();
        setContractsCount(res["hydra:totalItems"]);

        let yachtLength = 0;
        for (let history of res["hydra:member"]) {
          yachtLength += history.yachtLength;
        }

        if (res["hydra:totalItems"] > 0) {
          yachtLength = yachtLength / res["hydra:totalItems"];
        }

        setMediumYachtLength(yachtLength.toFixed(4));
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    };

    fetchStats();
  }, [dispatch]);

  return (
    <>
      <h1 className="page-title">{t("Statistics")}</h1>

      <Row>
        <Col md={4}>
          <div className="stats__brokers">
            <h2 className="page-subtitle" style={{ textAlign: "center" }}>
              {t("Brokers")}
            </h2>

            <StatsCard count={brokersCount} desc={t("brokers registered")} />
          </div>
        </Col>

        <Col md={4}>
          <div className="stats__companies">
            <h2 className="page-subtitle" style={{ textAlign: "center" }}>
              {t("Companies")}
            </h2>

            <StatsCard
              count={companiesCount}
              desc={t("companies registered")}
            />
          </div>
        </Col>

        <Col md={4}>
          <div className="stats__contracts">
            <h2 className="page-subtitle" style={{ textAlign: "center" }}>
              {t("Contracts")}
            </h2>

            <StatsCard count={contractsCount} desc={t("contracts made")}>
              <Link to="/admin/stats/contracts">
                <Button variant="outline-primary custom" size="sm">
                  <i className="fa fa-eye"></i>
                </Button>
              </Link>
            </StatsCard>

            <StatsCard
              count={mediumYachtLength}
              desc={t("Medium yacht length")}
            ></StatsCard>
          </div>
        </Col>
      </Row>
    </>
  );
};
