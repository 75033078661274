import React from "react";
import { connect } from "react-redux";

import Container from "react-bootstrap/Container";

import { Alerts } from "../_components";

/* Admin components */
import { AdminHeader } from "./admin";

import "./DefaultLayout.scss";
import "./AdminLayout.scss";

const AdminLayout = ({ children, dispatch }) => {
  return (
    <>
      <AdminHeader />

      <Container>
        <Alerts />
        <main>{children}</main>
      </Container>
    </>
  );
};

const connectedLayout = connect()(AdminLayout);
export { connectedLayout as AdminLayout };
