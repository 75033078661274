import { PrivateKey } from "./_components/PrivateKey";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";

import { decrypt, encrypt, getPublicKey } from "../_helpers";
import { Loader, ValidationModal } from "../_components";
import {
  emptyContractFolder,
  fetchContractFoldersByBroker,
} from "../_actions/contractFolders.actions";
import {
  brokersService,
  contractFieldValuesService,
  contractFoldersService,
  contractsService,
} from "../_services";
import { alertActions, emptyContract } from "../_actions";
import { alertConstants } from "../_constants";
import { Link } from "react-router-dom";
import { ContractDocument } from "./ContractDocument";
import { Document, pdf } from "@react-pdf/renderer";
import { createCharterDocumentObj } from "./common/utils";
import moment from "moment";
import { contractLogsService } from "../_services/contract.logs.service";
export const ContractFolderCharterList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [shareData, setShareData] = useState({
    shareEmail: "",
  });
  const { shareEmail } = shareData;

  const [formData, setFormData] = useState({
    name: "",
    lang: "en",
  });

  const { name, lang } = formData;

  const [isLoaderShown, setLoaderShown] = useState(false);
  const user = useSelector((state) => state.authentication.user);
  const contractFolders = useSelector((state) => state.contractFolders);
  const [isTitleModalShown, setTitleModalShown] = useState(false);
  const privateKey = useSelector((state) => state.cryptKeys.privateKey);
  const [currentSharedId, setCurrentSharedId] = useState(0);
  const [isShareModalShown, setShareModalShown] = useState(false);
  const [isDeleteModalShown, setDeleteModalShown] = useState({
    status: null,
    show: false,
  });
  const [currentDeleteId, setCurrentDeleteId] = useState(0);
  const [isDuplicateModalShown, setDuplicateModalShown] = useState(false);
  const [currentDpulicateId, setcurrentDpulicateId] = useState(0);
  const [duplicatedOriginalName, setDuplicatedOriginalName] = useState("");

  useEffect(() => {
    dispatch(emptyContractFolder());
    dispatch(emptyContract());
    dispatch(fetchContractFoldersByBroker(user.id, "charter"));
  }, [dispatch, user]);

  const handleTitleModalClose = () => {
    setTitleModalShown(false);
  };
  const shareHandleChange = (e) => {
    setShareData({ ...shareData, [e.target.name]: e.target.value });
  };
  const openTitleModal = () => {
    setTitleModalShown(true);
  };

  const handleTitleModalSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (!name || name === "") {
        dispatch(
          alertActions.setAlert(t("Name must be filled"), alertConstants.ERROR)
        );
        return;
      }

      const newContractFolder = {
        name: encrypt(name, user.publicKey, privateKey),
        owner: `/brokers/${user.id}`,
        status: "draft",
        lang: lang,
        folderType: "charter",
      };

      try {
        const addResponse = await contractFoldersService.add(newContractFolder);

        const log = {
          version: 1,
          action: `Creating a new agreement "${name}"`,
          author: user?.id,
          updatedFields: null,
        };

        await contractLogsService?.add(addResponse?.id, log);
        setFormData({ name: "" });

        dispatch(
          alertActions.setAlert(
            t("Contract has been added"),
            alertConstants.SUCCESS
          )
        );
        dispatch(fetchContractFoldersByBroker(user.id, "charter"));
        setTitleModalShown(false);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const isPrivateKeyValid = (info) => {
    if (!user.publicKey) {
      return false;
    }

    try {
      decrypt(info, user.publicKey, privateKey);

      return true;
    } catch (err) {
      return false;
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleShareModalSubmit = async (e) => {
    try {
      setShareModalShown(false);
      setLoaderShown(true);

      const res = await brokersService.findByEmail(shareEmail);

      if (res["hydra:totalItems"] === 1) {
        const shareToUser = res["hydra:member"][0];

        const contractFolderToShare = await contractFoldersService.find(
          currentSharedId
        );

        if (contractFolderToShare.sharedBrokers.length === 0) {
          const decryptedFolderName = decryptInfo(contractFolderToShare.name);
          const folder = {
            id: currentSharedId,
            name: encrypt(decryptedFolderName, user.publicKey, user.publicKey),
          };
          await contractFoldersService.encryptFolder(folder);
          var encryptedFieldValues = [];
          contractFolderToShare.contracts.map((contract) => {
            return contract.fieldValues.map((fieldValue) => {
              const decryptedFieldValue = decryptInfo(fieldValue.value);
              encryptedFieldValues.push({
                id: fieldValue.id,
                value: encrypt(
                  decryptedFieldValue,
                  user.publicKey,
                  user.publicKey
                ),
              });
              return true;
            });
          });

          await contractFieldValuesService.editMultiple(encryptedFieldValues);
        }
        await contractFoldersService.shareContractFolder(
          currentSharedId,
          shareToUser.id
        );
        setShareData({ shareEmail: "" });
        dispatch(fetchContractFoldersByBroker(user.id, "charter"));

        setLoaderShown(false);
        setShareModalShown(false);
      } else {
        dispatch(
          alertActions.setAlert(
            t("This user doesn't exist"),
            alertConstants.ERROR
          )
        );
        setLoaderShown(false);
      }
    } catch (err) {
      dispatch(
        alertActions.setAlert("An error occured : " + err, alertConstants.ERROR)
      );
      setLoaderShown(false);
    }
  };

  const decryptInfo = (info) => {
    if (!user.publicKey) {
      return info;
    }

    try {
      return decrypt(info, user.publicKey, privateKey);
    } catch (err) {
      return info;
      //dispatch(alertActions.setAlert(t('Incorrect key'), alertConstants.ERROR))
    }
  };
  const decryptSharedInfo = (info, sharedFrom) => {
    if (!sharedFrom.publicKey) {
      return info;
    }

    try {
      return decrypt(info, sharedFrom.publicKey, sharedFrom.publicKey);
    } catch (err) {
      return info;
    }
  };

  const deleteModalOptionSelected = async (option) => {
    if (option === "no") {
      setDeleteModalShown({ status: null, show: false });
    } else if (option === "yes") {
      try {
        await contractFoldersService.remove(currentDeleteId);

        dispatch(
          alertActions.setAlert(
            t("Contract has been removed"),
            alertConstants.SUCCESS
          )
        );

        dispatch(fetchContractFoldersByBroker(user.id, "charter"));
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      setDeleteModalShown({ status: null, show: false });
    }
  };

  const handledupicateContract = async (e) => {
    e.preventDefault();

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (!formData.name || formData.name === "") {
        dispatch(
          alertActions.setAlert(t("Name must be filled"), alertConstants.ERROR)
        );
      } else {
        if (formData.name === duplicatedOriginalName) {
          dispatch(
            alertActions.setAlert(
              t("Name must be different"),
              alertConstants.ERROR
            )
          );
        } else {
          const encryptedName = encrypt(
            `${formData.name}`,
            user.publicKey,
            privateKey
          );

          await contractFoldersService.duplicateContractFolder(
            currentDpulicateId,
            encryptedName
          );
          handleDuplicateModalClose();
          setFormData({ name: "" });
          dispatch(fetchContractFoldersByBroker(user.id, "charter"));
          dispatch(
            alertActions.setAlert(
              t("Contract has been duplicated"),
              alertConstants.SUCCESS
            )
          );
        }
      }
    }
  };

  const handleDuplicateModalClose = () => {
    setDuplicatedOriginalName("");
    setDuplicateModalShown(false);
  };
  const [showTransferModal, setShowTransferModal] = useState({
    contractPdf: null,
    show: false,
  });
  const [transferLoading, setTransferLoading] = useState(false);
  const [currentTransferEmail, setCurrentTransferEmail] = useState("");
  const [clientAddresses, setClientAddresses] = useState([]);
  const handleFocusOut = (e) => {
    if (e?.currentTarget?.value !== "") {
      setClientAddresses([...clientAddresses, e?.currentTarget?.value]);
      setCurrentTransferEmail("");
    }
  };
  const generatePdfFull = async (contractFolder) => {
    if (contractFolder) {
      let publicKeyToUse = getPublicKey(user, contractFolder);
      let documents = [];
      let codename = "";
      const baseContract = contractFolder.contracts.find(
        (c) => c?.template.codeName === "baseTemplateCharter"
      );
      if (baseContract !== undefined) {
        codename = "baseTemplate";
      } else {
        codename = "baseTemplateDay";
      }

      const baseContractObj = createCharterDocumentObj(
        baseContract,
        contractFolder,
        moment,
        user,
        privateKey,
        t
      );

      documents.push(baseContractObj);

      const legalMentionsDocument = {
        contract:
          contractFolder.lang === "en" ? "legalMentions" : "legalMentionsFr",
        jsonView: null,
      };

      documents.push(legalMentionsDocument);

      const blob = await pdf(
        <Document>
          {documents.map((doc, index) => (
            <ContractDocument
              key={index}
              contract={doc.contract}
              jsonView={doc.jsonView}
              status={contractFolder.status}
              user={user}
              lang={contractFolder.lang}
              codename={codename}
              type={contractFolder.folderType}
            />
          ))}
        </Document>
      ).toBlob();
      const decryptSecondArg =
        contractFolder?.sharedBrokers.length > 0 ||
        contractFolder?.createdFromShared
          ? contractFolder.owner.publicKey
          : publicKeyToUse;

      const decryptThirdArg =
        contractFolder?.sharedBrokers.length > 0 ||
        contractFolder?.createdFromShared
          ? contractFolder.owner.publicKey
          : privateKey;
      var filename = decrypt(
        contractFolder.name,
        decryptSecondArg,
        decryptThirdArg
      );

      filename += ".pdf";
      try {
        setTransferLoading(true);
        await contractsService
          ?.transferContract(
            blob,
            clientAddresses,
            filename,
            contractFolder?.id,
            user?.email
          )
          .then(() => {
            setTransferLoading(false);
            setClientAddresses([]);
            setShowTransferModal({ contractFolder: null, show: false });
            dispatch(
              alertActions.setAlert(
                t("Contract has been transferred"),
                alertConstants.SUCCESS
              )
            );
          });
      } catch (error) {
        setTransferLoading(false);
      }
    }
  };
  const handleTransfer = async (id) => {
    if (id) {
      const contractFolderData = await contractFoldersService.find(id);
      generatePdfFull(contractFolderData);
    }
  };

  const removeClient = (itemToRemove) => {
    const updatedItems = clientAddresses.filter(
      (item) => item !== itemToRemove
    );
    setClientAddresses(updatedItems);
  };
  const [showLogsModal, setShowLogsModal] = useState({
    contractFolder: null,
    show: false,
  });
  const [currentLogs, setCurrentLogs] = useState([]);

  const fetchLogs = useCallback(async () => {
    if (showLogsModal?.show === true) {
      const logs = await contractLogsService?.fetchLogsByFolder(
        showLogsModal?.contractFolder
      );
      setCurrentLogs(logs);
    }
  }, [showLogsModal]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);
  return (
    <>
      <Loader isShown={isLoaderShown} />

      {user.firstConnection && (
        <Redirect to={{ pathname: "/first-connection" }} />
      )}

      <Modal show={isTitleModalShown} onHide={handleTitleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To add a new contract, you must give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handleTitleModalSubmit}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="contractLang">
              <Form.Label>{t("Contract's lang")}</Form.Label>
              <Form.Control
                as="select"
                name="lang"
                value={lang}
                onChange={handleChange}
              >
                <option value="en">EN</option>
                <option value="fr">FR</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleTitleModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleTitleModalSubmit}>
            {t("Add")}
          </Button>
        </Modal.Footer>
      </Modal>

      <PrivateKey />
      <Modal show={isShareModalShown} onHide={() => setShareModalShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Share contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "You want to share a contract with another user of the platform. Please fill his email"
            )}
          </p>

          <Form onSubmit={handleShareModalSubmit}>
            <Form.Group controlId="userEmail">
              <Form.Label>{t("User's email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("User's email")}
                name="shareEmail"
                value={shareEmail}
                onChange={shareHandleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShareModalShown(false)}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleShareModalSubmit}>
            {t("Share")}
          </Button>
        </Modal.Footer>
      </Modal>
      {isDeleteModalShown.show && (
        /*isDeleteModalShown.status === "draft" && (*/
        <ValidationModal
          title={t("Delete contract")}
          text={
            "<p>" +
            t("WARNING ! This action cannot be undone.") +
            "</p>" +
            "<p>" +
            t("Do you wish to continue ?") +
            "</p>"
          }
          show={isDeleteModalShown.show}
          onOptionSelected={deleteModalOptionSelected}
        />
      )}

      <Modal show={isDuplicateModalShown} onHide={handleDuplicateModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("DUPLICATE CONTRACT")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To duplicate a new contract, you can give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handledupicateContract}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleDuplicateModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handledupicateContract}>
            {t("Duplicate")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTransferModal?.show}
        onHide={() =>
          setShowTransferModal({ contractFolder: null, show: false })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Transfer contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="userEmail">
              <Form.Label>
                {t("Enter one or multiple email addresses")}
              </Form.Label>
              <Form.Control
                type="email"
                disabled={transferLoading}
                name="transferEmails"
                onBlur={handleFocusOut}
                value={currentTransferEmail}
                onChange={(e) => setCurrentTransferEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
          <div className="d-flex flex-column">
            {clientAddresses?.map((address) => {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mx-1">{address}</span>
                  <i
                    key={address}
                    class="fa fa-trash"
                    role="button"
                    style={{ color: "#203f80" }}
                    onClick={() => removeClient(address)}
                  ></i>
                </div>
              );
            })}
          </div>
          {transferLoading && (
            <div className="d-flex align-items-center">
              <Spinner animation="border" variant="primary" className="mx-2" />
              <div className="d-flex flex-column" style={{ color: "#203f80" }}>
                <span>{t("Contract is being transferred.")}</span>
                <span>{t("Please wait a few moments.")}</span>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShareModalShown(false)}>
            {t("Cancel")}
          </Button>

          <Button
            variant="primary"
            onClick={() => handleTransfer(showTransferModal?.contractFolder)}
            disabled={transferLoading}
          >
            {t("Transfer")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showLogsModal?.show}
        onHide={() => {
          setShowLogsModal({ contractFolder: null, show: false });
          setCurrentLogs([]);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Historique")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "#b3b2b2",
                  color: "#FFFFFF",
                }}
              >
                <th>{t("Version")}</th>
                <th>{t("Date")}</th>
                <th>{t("Action")}</th>
                <th>{t("Author")}</th>
                <th>{t("Specific details")}</th>
              </tr>
            </thead>
            <tbody>
              {currentLogs?.map((log) => {
                return (
                  <tr role="button">
                    <td>{log?.version}</td>
                    <td>
                      {moment(log?.log_date).format("DD/MM/yyyy HH:mm:ss")}
                    </td>
                    <td>{log?.action}</td>
                    <td>{log?.author}</td>
                    {log?.updatedFields ? (
                      <td className="d-flex flex-column">
                        {log?.updatedFields?.map((field) => {
                          return (
                            <span>
                              Change <b>{field?.fieldName}</b> value to{" "}
                              <b>"{field?.fieldValue}"</b>
                            </span>
                          );
                        })}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <Row>
        <Col md={12}>
          <h1 className="page-title">
            {t("Your agreements")}
            {user?.publicKey &&
              ((contractFolders.items.length > 0 &&
                contractFolders.items.filter((cf) => !cf.sharedTo)[0].name) ||
                contractFolders.items.length === 0) &&
              privateKey &&
              privateKey !== "" &&
              (user?.company?.availableTokens === -1 ||
                user?.company?.availableTokens > 0) && (
                <Button
                  variant="success"
                  size="sm"
                  style={{
                    marginLeft: "15px",
                    padding: "2px 10px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                  onClick={openTitleModal}
                >
                  + {t("New")}
                </Button>
              )}
          </h1>

          <Table bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "#b3b2b2",
                  color: "#FFFFFF",
                }}
              >
                <th>{t("Name")}</th>

                <th>{t("Status")}</th>

                <th>{t("Lang")}</th>

                <th>{t("Version")}</th>

                <th>{t("Shared with")}</th>

                <th>{t("Actions")}</th>
              </tr>
            </thead>

            <tbody>
              {user?.publicKey &&
                privateKey &&
                privateKey !== "" &&
                contractFolders.items
                  .filter((cf) => !cf.sharedTo)
                  .map((contractFolder, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          {contractFolder.sharedBrokers.length > 0
                            ? decryptSharedInfo(contractFolder.name, user)
                            : decryptInfo(contractFolder.name)}
                        </td>

                        <td>
                          {contractFolder.status === "draft" && (
                            <span style={{ color: "#e9725b" }}>
                              {t("Draft")}
                            </span>
                          )}
                          {contractFolder.status === "contract" && (
                            <span style={{ color: "#218838" }}>
                              {t("Contract")}
                            </span>
                          )}
                        </td>

                        <td>
                          {contractFolder.lang ? contractFolder.lang : "en"}
                        </td>

                        <td>{contractFolder.version}</td>

                        <td>
                          {contractFolder?.sharedBrokers.map(
                            (sharedContract) => (
                              <React.Fragment key={sharedContract.id}>
                                {sharedContract.email}

                                {(contractFolder.sharedBrokers.length > 0 ||
                                  isPrivateKeyValid(contractFolder.name)) && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    style={{ marginLeft: "10px" }}
                                    onClick={async () => {
                                      setLoaderShown(true);
                                      if (
                                        contractFolder.sharedBrokers.length ===
                                        1
                                      ) {
                                        const decryptedFolderName =
                                          decryptSharedInfo(
                                            contractFolder.name,
                                            user
                                          );

                                        const folder = {
                                          id: contractFolder.id,
                                          name: encrypt(
                                            decryptedFolderName,
                                            user.publicKey,
                                            privateKey
                                          ),
                                        };
                                        await contractFoldersService.encryptFolder(
                                          folder
                                        );
                                        if (
                                          contractFolder.fieldValues.length >
                                            0 &&
                                          !contractFolder?.createdFromShared
                                        ) {
                                          var encryptedFieldValues = [];

                                          contractFolder.fieldValues.map(
                                            (fieldValue) => {
                                              const decryptedFieldValue =
                                                decryptSharedInfo(
                                                  fieldValue.value,
                                                  user
                                                );

                                              encryptedFieldValues.push({
                                                id: fieldValue.id,
                                                value: encrypt(
                                                  decryptedFieldValue,
                                                  user.publicKey,
                                                  privateKey
                                                ),
                                              });

                                              return true;
                                            }
                                          );
                                          await contractFieldValuesService.editMultiple(
                                            encryptedFieldValues
                                          );
                                        }
                                      }

                                      await contractFoldersService.refreshSharedInstancesContractFolder(
                                        contractFolder.id,
                                        sharedContract.id
                                      );
                                      setLoaderShown(false);
                                      dispatch(
                                        fetchContractFoldersByBroker(
                                          user.id,
                                          "charter"
                                        )
                                      );
                                    }}
                                    title={t("Delete")}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                )}

                                <br />
                              </React.Fragment>
                            )
                          )}
                        </td>
                        <td>
                          {(contractFolder.sharedBrokers.length > 0 ||
                            isPrivateKeyValid(contractFolder.name)) && (
                            <>
                              <Link
                                to={`/charters/folders/${contractFolder.id}`}
                              >
                                <OverlayTrigger
                                  key="see"
                                  placement="top"
                                  overlay={
                                    <Tooltip id="see">
                                      {t("See / Modify")}
                                    </Tooltip>
                                  }
                                >
                                  <Button variant="outline-primary" size="sm">
                                    <i className="fa fa-list-alt"></i>
                                  </Button>
                                </OverlayTrigger>
                              </Link>
                              <OverlayTrigger
                                key="share"
                                placement="top"
                                overlay={
                                  <Tooltip id="share">{t("Share")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setCurrentSharedId(contractFolder.id);
                                    setShareModalShown(true);
                                  }}
                                >
                                  <i className="fas fa-share-square"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="delete"
                                placement="top"
                                overlay={
                                  <Tooltip id="delete">{t("Delete")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setCurrentDeleteId(contractFolder.id);
                                    setDeleteModalShown({
                                      status: contractFolder.status,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="dupliquer"
                                placement="top"
                                overlay={
                                  <Tooltip id="dupliquer">
                                    {t("Duplicate")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setcurrentDpulicateId(contractFolder.id);
                                    const originalName = decryptInfo(
                                      contractFolder.name
                                    );
                                    setDuplicatedOriginalName(originalName);
                                    setDuplicateModalShown(true);
                                  }}
                                >
                                  <i className="fa fa-clone"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="transfer"
                                placement="top"
                                overlay={
                                  <Tooltip id="transfer">
                                    {t("Transfer")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setShowTransferModal({
                                      contractFolder: contractFolder?.id,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i class="fa fa-paper-plane"></i>{" "}
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="history"
                                placement="top"
                                overlay={
                                  <Tooltip id="history">{t("History")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setShowLogsModal({
                                      contractFolder: contractFolder?.id,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i class="fa fa-history"></i>{" "}
                                </Button>
                              </OverlayTrigger>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
