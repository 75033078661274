import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { Button, Modal, Form, Row, Col } from "react-bootstrap";

import { fetchContractsByBroker } from "../_actions/contracts.actions";

import { alertActions, userActions } from "../_actions";
import { alertConstants } from "../_constants";

import { contractsService, contractsTemplateService } from "../_services";

import { encrypt, decrypt } from "../_helpers";

import { PrivateKey } from "./_components/PrivateKey";

import { Link } from "react-router-dom";

export const ContractList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state.authentication.user);
  const contracts = useSelector((state) => state.contracts);

  const [templates, setTemplates] = useState([]);

  const [isTitleModalShown, setTitleModalShown] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    template: "",
  });

  const { name, template } = formData;

  const privateKey = useSelector((state) => state.cryptKeys.privateKey);

  useEffect(() => {
    if (user) {
      dispatch(fetchContractsByBroker(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    async function fetchContract() {
      try {
        const res = await contractsTemplateService.findAll();

        setTemplates(res["hydra:member"]);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }

    fetchContract();
  }, [dispatch]);

  useEffect(() => {
    if (templates.length > 0) {
      setFormData({ ...formData, template: templates[0].id });
    }
  }, [formData, templates]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTitleModalClose = () => {
    setTitleModalShown(false);
  };

  const openTitleModal = () => {
    setTitleModalShown(true);
  };

  const handleTitleModalSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (
        user.company.availableTokens !== -1 &&
        user.company.availableTokens < 0
      ) {
        dispatch(
          alertActions.setAlert(
            t("Your company doesn't have anymore token available"),
            alertConstants.ERROR
          )
        );
      }

      const newContract = {
        name: encrypt(name, user.publicKey, privateKey),
        owner: `/brokers/${user.id}`,
        template: `/contract_templates/${template}`,
      };

      try {
        await contractsService.add(newContract);

        dispatch(
          alertActions.setAlert(
            t("Contract has been added"),
            alertConstants.SUCCESS
          )
        );
        dispatch(fetchContractsByBroker(user.id));
        setTitleModalShown(false);

        // Update user for company token refresh
        let userInLocal = JSON.parse(localStorage.user);
        if (userInLocal.refresh_token) {
          dispatch(userActions.refreshToken(userInLocal.refresh_token));
        }
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const decryptInfo = (info) => {
    if (!user.publicKey) {
      return info;
    }

    try {
      return decrypt(info, user.publicKey, privateKey);
    } catch (err) {
      dispatch(alertActions.setAlert(t("Incorrect key"), alertConstants.ERROR));
      return info;
    }
  };

  return (
    <>
      {/*user && user.firstConnection && (
        <Redirect to={{ pathname: '/first-connection' }} />
      )*/}

      <Modal show={isTitleModalShown} onHide={handleTitleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To add a new contract, you must give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handleTitleModalSubmit}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="contractTemplate">
              <Form.Label>{t("Template")}</Form.Label>
              <Form.Control
                as="select"
                name={t("Template")}
                value={template}
                onChange={handleChange}
              >
                {templates.map((template) => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleTitleModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleTitleModalSubmit}>
            {t("Add")}
          </Button>
        </Modal.Footer>
      </Modal>

      <PrivateKey />

      <Row>
        <Col md={6}>
          <h1 className="page-title">
            {t("Your contracts")}
            {user?.publicKey &&
              privateKey &&
              privateKey !== "" &&
              (user?.company?.availableTokens === -1 ||
                user?.company?.availableTokens > 0) && (
                <Button
                  variant="primary"
                  size="sm"
                  style={{ marginLeft: "15px" }}
                  onClick={openTitleModal}
                >
                  {t("Add")}
                </Button>
              )}
          </h1>

          {contracts.items.map((contract, index) => (
            <div key={index}>
              {user?.publicKey && privateKey && privateKey !== "" ? (
                <p>
                  {decryptInfo(contract.name)}
                  <Link to={`/contracts/${contract.id}`}>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginLeft: "10px" }}
                    >
                      {t("Edit")}
                    </Button>
                  </Link>
                </p>
              ) : (
                <p>{contract.name}</p>
              )}
            </div>
          ))}
        </Col>

        <Col md={6}>
          <h2 className="page-title">{t("Your company")}</h2>

          <p>
            {t("You're attached to the company")}{" "}
            <strong>{user?.company?.name}</strong> <br />
            {"Available tokens"} :{" "}
            {user?.company?.availableTokens === -1
              ? t("Unlimited")
              : user?.company?.availableTokens}
          </p>
        </Col>
      </Row>
    </>
  );
};
