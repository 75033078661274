import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Button, Row, Col } from "react-bootstrap";

import { alertActions } from "../_actions";
import { alertConstants } from "../_constants";

import { useTranslation } from "react-i18next";

import { Loader } from "../_components";

import axios from "axios";

export const ContactForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    subject: t("Financial aspects of the contract"),
    message: "",
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [isLoaderShown, setLoaderShown] = useState(false);

  const acceptedFileType = [
    ".doc",
    ".docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".pdf",
    "application/pdf",
    ".zip",
    "application/zip",
    "application/x-zip-compressed",
    ".jpg",
    ".jpeg",
    ".png",
    "image/*",
    "image/jpeg",
    "image/jpg",
    "image/png",
  ];

  const [isFormSent, setFormSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { name, email, company, subject, message } = formData;

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      if (acceptedFileType.find((f) => file.type === f)) {
        // Type is OK
        setSelectedFile(file);
        setIsFilePicked(true);
      } else {
        dispatch(
          alertActions.setAlert(
            `Le fichier ${file.name} ne possède pas le bon format, et a donc été retiré.`,
            alertConstants.ERROR
          )
        );
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoaderShown(true);

    try {
      const formData = new FormData();
      formData.set("name", name);
      formData.set("email", email);
      formData.set("company", company);
      formData.set("subject", subject);
      formData.set("message", message);

      if (isFilePicked) {
        formData.set("filename", selectedFile.name);

        formData.append("file", selectedFile);
      }

      const apiUrl = `${process.env.REACT_APP_API_ENTRYPOINT}/contact_form`;

      let params = { headers: [] };
      params.headers["Content-Type"] = "multipart/form-data";

      const res = await axios.post(`${apiUrl}`, formData, params);

      if (res.status !== 200) {
        dispatch(
          alertActions.setAlert(t("An error occured"), alertConstants.ERROR)
        );
        setLoaderShown(false);

        return;
      }
    } catch (err) {
      console.error(`ERROR : ${err}`);
      dispatch(
        alertActions.setAlert(t("An error occured"), alertConstants.ERROR)
      );
      setLoaderShown(false);

      return;
    }

    dispatch(
      alertActions.setAlert(
        t("Your message has been sent"),
        alertConstants.SUCCESS
      )
    );

    setFormSent(true);
    setLoaderShown(false);
  };

  return (
    <>
      <Loader isShown={isLoaderShown} />

      {!isFormSent && (
        <>
          <h1>{t("Support contact form")}</h1>

          <Row className="justiy-content-md-center">
            <Col md={6}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>{t("Your name")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>{t("Your email")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formName">
                  <Form.Label>{t("Your company")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="company"
                    value={company}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formSubject">
                  <Form.Label>{t("Your problem is about")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="subject"
                    value={subject}
                    onChange={handleChange}
                  >
                    <option value={t("Financial aspects of the contract")}>
                      {t("Financial aspects of the contract")}
                    </option>
                    <option value={t("Platform use")}>
                      {t("Platform use")}
                    </option>
                    <option value={t("Technical issue with the platform")}>
                      {t("Technical issue with the platform")}
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formMessage">
                  <Form.Label>{t("Your message")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    required
                    name="message"
                    value={message}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="formFile">
                  <Form.File
                    id="formFile"
                    label={t("Attachment")}
                    custom
                    multiple={false}
                    onChange={handleFileChange}
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf,.zip,application/zip,application/x-zip-compressed,.jpg,.jpeg,.png,image/*"
                  />

                  <Form.Text className="text-muted">
                    {selectedFile?.name}
                  </Form.Text>
                </Form.Group>

                <Button type="submit" variant="success">
                  {t("Send")}
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}

      {isFormSent && (
        <>
          <p>
            {t(
              "Thanks, your message has been sent. We'll come back to you as soon as possible."
            )}
          </p>
        </>
      )}
    </>
  );
};
