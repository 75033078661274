import { createSlice } from '@reduxjs/toolkit'

const ITEMS_PER_PAGE = process.env.REACT_APP_API_NB_ITEMS_PER_PAGE

const initialState = {
  items: [],
  item: {},
}

export const contractsHistoriesSlice = createSlice({
  name: 'contractsHistories',
  initialState,
  reducers: {
    itemsLoaded: (state, action) => {
      const nbPages = Math.ceil(
        action.payload['hydra:totalItems'] / ITEMS_PER_PAGE
      )

      const hydraView = action.payload['hydra:view']
      let page = null

      if (hydraView) {
        const hydraId = action.payload['hydra:view']['@id']
        const parametersStr = hydraId.split('?')[1]

        const parameters = parametersStr.split('&')

        for (let paramStr of parameters) {
          const param = paramStr.split('=')

          if (param[0] === 'page') {
            page = parseInt(param[1])
          }
        }
      } else {
        page = 1
      }

      return {
        ...state,
        items: action.payload['hydra:member'],
        nbPages: nbPages,
        page: page,
      }
    },

    itemLoaded: (state, action) => {
      return {
        ...state,
        item: action.payload,
      }
    },

    fetchError: (state) => {
      return state
    },

    empty: (state) => {
      return initialState
    },
  },
})

export const {
  itemsLoaded,
  itemLoaded,
  fetchError,
} = contractsHistoriesSlice.actions
export default contractsHistoriesSlice.reducer
