import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  alertActions,
  chooseMainForCompany,
  fetchCompany,
  tokenManagementCompany,
} from "../../_actions";

import { Button, Row, Col, Table, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import { CSVLink } from "react-csv";

import { history } from "../../_helpers";

import moment from "moment";

import "./CompanyShow.scss";
import { alertConstants } from "../../_constants";

import { CompanyInformations } from "./components/CompanyInformations";

export const CompanyShow = ({ id }) => {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.companies.item);
  const { t } = useTranslation();

  const [isTokenModalShown, setIsTokenModalShown] = useState(false);
  const [isTokenFieldShown, setTokenFieldShown] = useState(true);

  const [formData, setFormData] = useState({
    availableTokens: 0,
    subscriptionEndDate: moment("now"),
    illimitedTokens: false,
  });

  const { availableTokens, subscriptionEndDate, illimitedTokens } = formData;

  const [csvDatas, setCsvDatas] = useState(null);

  const csvHeaders = [
    {
      label: t("ECPY Id"),
      key: "ecpyId",
    },

    {
      label: t("Available tokens"),
      key: "availableTokens",
    },

    {
      label: t("Subscription end date"),
      key: "subscriptionEndDate",
    },
  ];

  useEffect(() => {
    dispatch(fetchCompany(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (company) {
      setFormData({
        availableTokens: company.availableTokens,
        subscriptionEndDate: moment(company.subscriptionEndDate).format(
          "YYYY-MM-DD"
        ),
        illimitedTokens: company.availableTokens === -1 ? true : false,
      });

      setCsvDatas([
        {
          ecpyId: company.ecpyId,
          availableTokens:
            company.availableTokens === -1
              ? t("Illimited")
              : company.availableTokens,
          subscriptionEndDate: moment(company.subscriptionEndDate).format(
            "YYYY-MM-DD"
          ),
        },
      ]);
    }
  }, [company, t]);

  const displayMainBrokerAction = (broker) => {
    if (broker.id === company.mainBroker?.id) {
      return "Actuel";
    }

    return (
      <Button
        onClick={() => chooseMainBroker(broker)}
        variant="outline-primary"
        size="sm"
      >
        Choisir
      </Button>
    );
  };

  const chooseMainBroker = (broker) => {
    const mainBrokerData = { mainBroker: broker.id };
    dispatch(chooseMainForCompany(id, mainBrokerData));

    //dispatch(updateCompany(id, updatedCompany));
  };

  const handleTokenModalClose = () => {
    setIsTokenModalShown(false);
  };

  const showTokenModal = () => {
    setIsTokenModalShown(true);
  };

  const handleTokenModalUpdate = (e) => {
    e.preventDefault();

    let tokens = 0;
    if (illimitedTokens === true) {
      tokens = -1;
    } else {
      tokens = parseInt(availableTokens);
      if (tokens < 0) {
        dispatch(
          alertActions.setAlert(
            t("You must specified a positive number for tokens"),
            alertConstants.ERROR
          )
        );
        return;
      }
    }

    if (moment(subscriptionEndDate).isBefore(moment())) {
      dispatch(
        alertActions.setAlert(
          t("You must specified a future date for end of subscription"),
          alertConstants.ERROR
        )
      );
      return;
    }
    let updatedCompany = {
      ...company,
      availableTokens: tokens,
      subscriptionEndDate: subscriptionEndDate,
    };
    dispatch(tokenManagementCompany(id, updatedCompany));

    setIsTokenModalShown(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal show={isTokenModalShown} onHide={handleTokenModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Tokens management")}</Modal.Title>
        </Modal.Header>

        <Form>
          <Modal.Body>
            {isTokenFieldShown && (
              <Form.Group controlId="availableTokens">
                <Form.Label>{t("Available tokens")}</Form.Label>
                <Form.Control
                  type="number"
                  name="availableTokens"
                  value={availableTokens}
                  onChange={handleChange}
                />
              </Form.Group>
            )}

            <Form.Group controlId="illimitedTokens">
              <Form.Check
                type="checkbox"
                label={t("Illimited tokens")}
                value={illimitedTokens}
                onChange={(e) => {
                  if (e.target.checked) {
                    setTokenFieldShown(false);
                    setFormData({
                      ...formData,
                      illimitedTokens: true,
                    });
                  } else {
                    setTokenFieldShown(true);
                    setFormData({
                      ...formData,
                      illimitedTokens: false,
                    });
                  }
                }}
                checked={illimitedTokens === true}
              />
            </Form.Group>

            <Form.Group controlId="subscriptionEndDate">
              <Form.Label>{t("Subscription end date")}</Form.Label>
              <Form.Control
                type="date"
                name="subscriptionEndDate"
                value={subscriptionEndDate}
                onChange={handleChange}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleTokenModalClose}>
              Fermer
            </Button>

            <Button
              type="submit"
              variant="outline-danger"
              onClick={handleTokenModalUpdate}
            >
              {t("Update")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <div className="company">
        <h2 className="page-title company__title">
          <div className="float-right">
            {csvDatas && (
              <CSVLink data={csvDatas} headers={csvHeaders}>
                <Button variant="primary">{t("CSV export")}</Button>
              </CSVLink>
            )}
          </div>

          {company.name}
        </h2>

        <Row>
          <Col md={4}>
            <CompanyInformations company={company} />

            <div className="company__btns">
              <Link to={"/admin/companies/edit/" + id}>
                <Button variant="warning">{t("Update")}</Button>
              </Link>

              <Button onClick={history.goBack} variant="outline-primary">
                {t("Go back")}
              </Button>
            </div>
          </Col>

          <Col md={8}>
            <div className="company__tokens">
              <h3 className="page-subtitle">
                {t("Tokens management")}
                <Button
                  variant="outline-warning"
                  size="sm"
                  style={{ marginLeft: "15px" }}
                  onClick={showTokenModal}
                >
                  {t("Update")}
                </Button>
              </h3>

              <p>
                {t("Company subscription is valid until this date")} :{" "}
                <strong>
                  {company.subscriptionEndDate
                    ? moment(company.subscriptionEndDate).format("DD/MM/YYYY")
                    : t("Not set yet")}
                </strong>
              </p>

              <p>
                <strong>{t("Available tokens")} : </strong>{" "}
                {company.availableTokens === -1
                  ? t("Unlimited")
                  : company.availableTokens}{" "}
                <br />
                <strong>{t("Consumed tokens")} : </strong>{" "}
                {company.consumedTokens}
              </p>
            </div>

            <div className="company__brokers">
              <h3 className="page-subtitle">{t("Brokers")}</h3>

              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>{t("Firstname")}</th>
                    <th>{t("Name")}</th>
                    <th>{t("Email")}</th>
                    <th>{t("Main")}</th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {company.brokers?.map((broker) => (
                    <tr
                      key={broker.id}
                      className={
                        company.mainBroker?.id === broker.id
                          ? "company__row--active"
                          : ""
                      }
                    >
                      <td>{broker.firstname}</td>
                      <td>{broker.name}</td>
                      <td>{broker.email}</td>
                      <td>{displayMainBrokerAction(broker)}</td>
                      <td>
                        <Link to={"/admin/brokers/" + broker.id}>
                          <i className="brokers-list__icon fas fa-eye"></i>
                        </Link>

                        <Link to={"/admin/brokers/edit/" + broker.id}>
                          <i className="brokers-list__icon fas fa-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
