import React from "react";

export default function TermsFr() {
  return (
    <>
      <h2 className="terms-title">
        POLITIQUE DE CONFIDENTIALITÉ DE LA PLATEFORME DE CONTRAT DE TRANSPORT
        ECPY
      </h2>
      <p>
        La plateforme disponible sur{" "}
        <a
          className="terms-link"
          href="https://contracts.ecpy.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://contracts.ecpy.org/
        </a>{" "}
        est fournie par le Comité Européen pour le Yachting Professionnel
        (ECPY), dont le siège social est situé au Port de Nice, 06300 Nice,
        France et est mise à disposition conformément aux Termes et Conditions.
      </p>
      <p>
        Afin de fournir les services sur la plateforme, ECPY doit agir en tant
        que Responsable du Traitement des Données conformément au règlement de
        l'UE 2016/679 RGPD. Les données transmises via la plateforme, en
        utilisant les fonctions disponibles une fois connecté, peuvent être
        traitées sous le Contrat de Traitement des Données mentionné dans les
        Termes et Conditions.
      </p>
      <p>
        En plus des données résultant de la relation contractuelle entre
        l'utilisateur et ECPY, nous collectons des données visant à la gestion
        technique et opérationnelle de la plateforme (adresses IP, e-mail,
        numéro de téléphone, etc.). Des cookies peuvent ainsi être utilisés pour
        gérer la connexion et le maintien de la session active, le niveau de
        sécurité nécessaire ainsi que l'amélioration des services fournis.
      </p>
      <p>
        Les données seront conservées pour toute la durée du contrat, ainsi que
        pour le temps supplémentaire lié à la prescription des droits découlant
        de l'exécution du contrat. Vous pouvez exercer vos droits relatifs au
        RGPD à tout moment. Vous pouvez demander l'accès, la rectification,
        l'annulation, l'intégration des données vous concernant, ainsi que
        demander la limitation du traitement ; recevoir les données dans un
        format structuré, couramment utilisé et lisible par machine, vous
        opposer en tout ou partie à l'utilisation des données, révoquer tout
        consentement donné ainsi qu'exercer les autres droits reconnus.
      </p>
      <p>
        Conformément au règlement de l'UE, les utilisateurs disposent des droits
        suivants :
      </p>

      <ul>
        <li>
          Accès et rectification, mise à jour, complétude des données des
          utilisateurs droit de verrouillage ou de suppression des données
          personnelles lorsque celles-ci sont inexactes, incomplètes,
          équivoques, obsolètes ou limiter le traitement des données des
          utilisateurs (article 15-16-17-18 RGPD)
        </li>
        <li>Retirer le consentement à tout moment (article 13-2c RGPD)</li>
        <li>
          S'opposer au traitement et à l'automatisation des données des
          utilisateurs et sur la base de leur consentement/contrat (article
          20-21 RGPD)
        </li>
        <li>
          Définir le sort des données des utilisateurs après leur mort et
          choisir à qui nous devrons communiquer, ou non, ses données à un tiers
          qu'ils ont préalablement désigné ou détruire ses données à moins que
          leur conservation ne soit nécessaire à des fins probatoires ou pour
          remplir une obligation légale.
        </li>
      </ul>
      <p>
        Nous pouvons mettre à jour notre politique de confidentialité
        régulièrement. Nous vous informerons de tout changement en publiant des
        mises à jour sur cette page et vous conseillons de revoir périodiquement
        cette politique pour tout changement.
      </p>

      <p>
        Pour nous contacter, vous pouvez écrire à :{" "}
        <a className="terms-link" href="mailto:info@ecpy.org">
          info@ecpy.org
        </a>
      </p>
      <h2 className="terms-title">POLITIQUES DE CONFIDENTIALITÉ ET COOKIES</h2>
      <p>
        Ce document fournit des informations sur le traitement des données
        personnelles collectées via ce site Web et constitue donc une
        information pour les personnes concernées en conformité avec les
        réglementations applicables en matière de protection des données
        personnelles. La divulgation est conforme au règlement de l'UE 2016/679
        et concerne uniquement le présent site Web https://contracts.ecpy.org/.
      </p>
      <p>
        Le site Web (ecpy.org) est hébergé et créé au moyen de la plateforme
        Odoo. Certains services sont fournis par MailChimp, une marque déposée
        par The Rocket Science Group LLC.
      </p>
      <p>
        The platform available at https://contracts.ecpy.org/ is
        custom-developed and hosted on Microsoft Azure (France region).
      </p>
      <h2 className="terms-title">RESPONSABLE DE TRAITEMENT</h2>
      <p>
        Le Comité Européen pour le Yachting Professionnel avec des bureaux
        enregistrés à Nice – 06300 Nice – Quai Amiral Infernet.
      </p>
      <h2 className="terms-title">QUELLES DONNÉES SONT TRAITÉES ?</h2>
      <p>
        Lors de votre navigation sur les pages de ce site Web, nous collectons :
      </p>
      <ul>
        <li>
          Données de connexion : données personnelles nécessaires au
          fonctionnement du site Web, telles que les adresses IP ou les noms de
          domaine des ordinateurs utilisés par les utilisateurs qui se
          connectent au site,
        </li>
        <li>
          Adresses URI (Uniform Resource Identifier) des ressources demandées,
          le moment de la demande, la méthode utilisée pour soumettre la demande
          au serveur, la taille du fichier obtenu en réponse, le code numérique
          indiquant le statut de la réponse du serveur et d'autres paramètres
          liés au système d'exploitation et à l'environnement informatique des
          utilisateurs, les informations relatives au comportement sur les sites
          Web, par exemple l'utilisation de cookies.
        </li>
      </ul>

      <p>
        Les cookies sont de petits fichiers texte que le site Web place sur les
        appareils utilisés, qu'il s'agisse d'ordinateurs ou de dispositifs
        mobiles, enregistrés dans les fichiers de répertoire utilisés par le
        navigateur Web de l'utilisateur. Il existe plusieurs types de cookies,
        certains pour rendre l'utilisation du site Web plus efficace, d'autres
        pour activer certaines fonctions. Il peut également y avoir d'autres
        cookies et des pistages par pixels placés dans les services et
        applications utilisés par le site Web (par exemple, cartes, partage
        social, newsletters, etc.).
      </p>
      <h2 className="terms-title">DANS QUELS BUTS ?</h2>
      <ul>
        <li>
          Objectifs contractuels : consulter les pages Web et utiliser les
          services fournis sur le site{" "}
          <a
            className="terms-link"
            href="https://contracts.ecpy.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://contracts.ecpy.org/
          </a>
        </li>
        <li>
          Obligations légales : respecter les obligations légales conformément
          aux réglementations applicables et à la législation nationale et
          supranationale.
        </li>
        <li>
          Fonctionnement du site Web : au cours de leurs fonctions régulières,
          les systèmes informatiques et les procédures logicielles à la base du
          fonctionnement du site Web acquièrent des données dont la transmission
          est implicite dans l'utilisation des protocoles de communication
          Internet. L'objectif de la collecte de ces informations n'est pas
          d'être associé à des sujets identifiables mais peut, par le traitement
          et la correspondance avec d'autres données détenues par le responsable
          de traitement ou par des tiers, permettre l'identification des
          utilisateurs du site.
        </li>
        <li>
          Communication avec l'utilisateur sur demande : les données transmises
          par l'envoi entièrement facultatif, explicite et volontaire de
          messages électroniques aux adresses spécifiées sur ce site Web
          impliquent l'acquisition conséquente de l'adresse électronique de
          l'utilisateur, nécessaire pour répondre à ses demandes, et de toute
          autre donnée personnelle incluse dans le message par l'utilisateur.
        </li>
        <li>
          Communication avec l'utilisateur à des fins de marketing : les données
          collectées sont utilisées à des fins de marketing en envoyant – via
          des procédures de contact automatiques (telles que SMS, MMS et emails)
          ainsi que traditionnelles – des informations promotionnelles et
          commerciales liées aux services et/ou produits, ou pour faire la
          publicité d'événements de l'entreprise ou la réalisation d'analyses de
          marché et statistiques. Les communications peuvent également se
          référer à des activités de marketing organisées pour des tiers.
        </li>
        <li>
          Newsletter : envoyée uniquement sur votre consentement et abonnement
          explicite à ce service.
        </li>
        <li>
          Fournir et maintenir le service, vous informer de tout changement à
          notre service et même vous permettre de participer aux fonctionnalités
          interactives de nos services lorsque vous choisissez de le faire
        </li>
        <li>
          Fournir un service client et un soutien, détecter, prévenir et
          résoudre les problèmes techniques.
        </li>
      </ul>

      <p>
        La personne concernée doit exprimer son consentement au traitement des
        données personnelles à des fins spécifiques.
      </p>

      <h2 className="terms-title">COLLECTE ET TRAITEMENT DES DONNÉES</h2>
      <p>Les données collectées via le site Web sont conservées :</p>

      <ul>
        <li>
          Dans le cas de traitements à des fins contractuelles et de conformité
          aux exigences légales : pour toute la durée du service/adhésion et
          après sa résiliation pour une période n'excédant pas 10 ans.
        </li>
        <li>
          Dans le cas de traitements à des fins de marketing : jusqu'à la
          révocation du consentement exprimé par la personne concernée à ces
          fins.
        </li>
        <li>
          Dans le cas de traitements requis pour le fonctionnement du site Web :
          pour toute la durée de la session de navigation sur les sites Web et
          pour la période la plus longue d'analyse, dans tous les cas pour une
          période n'excédant pas 12 mois. Après les délais de conservation
          mentionnés, vos données personnelles seront détruites, supprimées ou
          rendues anonymes, conformément aux procédures techniques de
          suppression et de sauvegarde.
        </li>
      </ul>
      <p>
        Vos données personnelles peuvent être partagées, lorsque cela est
        strictement nécessaire aux fins indiquées, avec :
      </p>

      <ul>
        <li>
          Des tiers spécifiquement désignés par le responsable de traitement
          pour traiter les données personnelles, nécessaires à la réalisation
          des activités strictement liées à la fourniture des services,
        </li>
        <li>
          Des tiers fournissant des services aux entreprises, agissant
          typiquement en tant que sujets chargés du traitement, tels que les
          gestionnaires de services de communication, de courrier électronique,
          de livraison de courrier, de services techniques pour la
          gestion/maintenance du service ou d'autres fournisseurs de services
          d'information.
        </li>
        <li>
          Des entreprises fournissant un soutien lors de la réalisation
          d'enquêtes de marché.
        </li>
      </ul>
      <h2 className="terms-title">QUELS DROITS PEUVENT ÊTRE EXERCÉS ?</h2>
      <p>
        Il est possible d'exercer à tout moment les droits spécifiés dans le
        règlement UE 679/2016. Plus spécifiquement, les droits de demander au
        responsable de traitement l'accès aux données concernant l'utilisateur,
        leur modification ou annulation, l'intégration de données incomplètes,
        la limitation ou le traitement ; de demander les données fournies dans
        un format structuré d'usage courant et lisible par un dispositif
        automatique ; de révoquer le consentement accordé concernant le
        traitement et de s'opposer, partiellement ou entièrement, à
        l'utilisation des données ; et d'exercer d'autres droits accordés par
        les règles et réglementations applicables. Dans le cas où l'utilisateur
        croit que l'un des traitements enfreint la réglementation actuelle sur
        le traitement des données personnelles, il peut nous contacter pour
        résoudre le problème. Certaines fonctions du site Web permettent
        d'exercer directement ses droits via un accès Internet, comme par
        exemple avec la newsletter. Pour plus d'informations sur l'exercice de
        vos droits, il est possible de nous contacter par e-mail à l'adresse
        suivante :{" "}
        <a className="terms-link" href="mailto:info@ecpy.org">
          info@ecpy.org
        </a>
        .
      </p>
      <h2 className="terms-title">POLITIQUE DES COOKIES</h2>
      <p>
        Le site Web du Comité Européen pour le Yachting Professionnel,
        <a className="terms-link" href="https://www.ecpy.org/" target="_blank" rel="noopener noreferrer">
          https://www.ecpy.org/
        </a>
        , utilise des cookies pour garantir le bon fonctionnement des procédures
        et améliorer l'utilisation des applications en ligne. Ce document
        fournit des informations détaillées sur les cookies et leur utilisation,
        ainsi que sur les technologies similaires, et sur la manière de les
        gérer. Ce document est intégré à l'avis complet sur le traitement des
        données personnelles sur notre site Web.
      </p>
      <p>
        Les cookies sont de petits fichiers texte créés par une application Web
        et sauvegardés sur le client choisi par l'utilisateur pour naviguer sur
        internet (PC, smartphone, tablette), pour être envoyés lors des accès
        ultérieurs à internet par le même utilisateur. Les cookies permettent la
        collecte d'informations sur la navigation de l'utilisateur, par exemple
        pour se souvenir des préférences concernant la langue ou la devise
        utilisée pour un achat. Les cookies peuvent être sauvegardés de manière
        permanente sur un appareil et avoir une durée variable (par exemple,
        cookies persistants), mais peuvent être effacés lorsque l'utilisateur
        ferme le navigateur Web (cookies de session). Les cookies peuvent être
        installés par le site Web que vous visitez (cookies de première partie)
        ou par d'autres sites Web (cookies de tiers). Un bon exemple est
        représenté par la présence de "plugins sociaux" pour Instagram,
        FacWebook, LinkedIn et YouTube. Ce sont des parties du site visité
        générées directement par les sites Web et intégrées dans la page du site
        hôte. La présence de ces plugins entraîne la transmission de cookies
        vers et depuis tous les sites tiers. Pour plus de transparence et de
        commodité, vous trouverez ci-dessous l'adresse Web des différentes
        notices et moyens de gestion des cookies :
      </p>
      <p>
        <a
          className="terms-link"
          href="https://www.facWebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facWebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0
        </a>
      </p>
      <p>
        <a
          className="terms-link"
          href="https://fr.linkedin.com/legal/cookie-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://fr.linkedin.com/legal/cookie-policy
        </a>
      </p>
      <p>
        <a
          className="terms-link"
          href="https://www.facWebook.com/help/instagram/1896641480634370"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facWebook.com/help/instagram/1896641480634370
        </a>
      </p>
      <p>
        <a
          className="terms-link"
          href="https://www.youtube.com/intl/ALL_fr/howyoutubeworks/our-commitments/protecting-user-data/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.youtube.com/intl/ALL_fr/howyoutubeworks/our-commitments/protecting-user-data/
        </a>
      </p>
      <p>
        Selon les caractéristiques et l'utilisation des cookies, nous pouvons
        distinguer différentes catégories :
      </p>

      <ul>
        <li>
          Cookies strictement nécessaires : ces cookies sont cruciaux pour le
          bon fonctionnement du site Web et sont utilisés pour gérer la session,
          la connexion et l'accès aux fonctions réservées au site Web. La durée
          des cookies est strictement limitée à la session de travail (ils sont
          annulés une fois le navigateur Web fermé).
        </li>
        <li>
          Cookies d'analyse et de performance : ces cookies sont utilisés pour
          collecter et analyser le trafic et l'utilisation du site Web. Ces
          cookies peuvent détecter, par exemple, si le même utilisateur se
          reconnecte à différents moments. Ils peuvent également surveiller le
          système et améliorer ses performances et son utilisation. La
          désactivation de ces cookies n'entraîne pas une perte de
          fonctionnalités.
        </li>
        <li>
          Cookies de profilage : ce sont des cookies utilisés pour profiler les
          préférences de l'utilisateur et améliorer son expérience sur le Web,
          ainsi que les combiner à d'autres informations.
        </li>
      </ul>
      <p>
        Le site inclut également des composants transmis par Google Analytics,
        un service Web offert par Google. Dans ce cas, ces cookies tiers sont
        collectés et gérés de manière anonyme pour surveiller et améliorer les
        performances du site hôte. Google Analytics utilise des cookies pour
        collecter et analyser de manière anonyme les informations sur le
        comportement d'utilisation du site Web (y compris l'adresse IP des
        utilisateurs). Ces informations sont traitées et rassemblées par Google
        Analytics pour élaborer des rapports pour les opérateurs concernant les
        activités sur le site Web. Google ne relie pas les adresses IP à
        d'autres données détenues par Google, ni ne tente d'associer une adresse
        IP à l'identité d'un utilisateur. Pour plus d'informations, veuillez
        visiter{" "}
        <a
          href="https://policies.google.com/technologies/cookies?hl=en-US"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/technologies/cookies?hl=en-US
        </a>
        .
      </p>
      <p>
        Les cookies utilisés par le site visent à identifier les visiteurs
        uniques et à suivre la session d'un visiteur sur le site Web, à se
        souvenir si un visiteur a accepté de placer des cookies d'analyse sur
        son navigateur si un site restreint le placement de cookies, à
        identifier les visiteurs uniques de notre site Web, à gérer et à
        améliorer la gestion de notre plateforme, et à gérer l'abonnement aux
        newsletters. L'utilisateur peut décider d'accepter ou non les cookies en
        utilisant les paramètres de son navigateur. Attention : une
        désactivation partielle ou totale des cookies techniques peut
        compromettre l'utilisation des fonctions du site Web. En revanche,
        l'utilisation des contenus publics est possible même en désactivant
        complètement les cookies. La désactivation des cookies tiers n'affecte
        pas l'expérience de navigation de quelque manière que ce soit. Pour
        désactiver les cookies, vous avez la possibilité d'utiliser un
        navigateur anonyme ou un mode incognito ou de désactiver les cookies via
        votre navigateur Web et des services tiers. Cependant, si l'utilisateur
        choisit de désactiver partiellement ou totalement les cookies du site
        Web, certaines parties, ou tous les services fournis par des tiers,
        certaines fonctions et performances de notre site Web peuvent être
        réduites et/ou indisponibles. Les paramètres des cookies peuvent être
        définis spécifiquement selon les différents sites Web et applications
        Web. Vous aurez la possibilité de recevoir davantage d'informations par
        e-mail en nous contactant à{" "}
        <a className="terms-link" href="mailto:info@ecpy.org">
          info@ecpy.org
        </a>
        .
      </p>
    </>
  );
}
