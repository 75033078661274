import styles from "./ContractStyles";
import timesNewRoman from "../fonts/times-new-roman.ttf";
import timesNewRomanBold from "../fonts/times-new-roman-grassetto.ttf";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: "Times New Roman",
  src: timesNewRoman,
});

Font.register({
  family: "Times New Roman Bold",
  src: timesNewRomanBold,
});
const jsonView = {
  type: "page",
  items: [
    {
      type: "view",
      styles: {
        paddingLeft: 25,
        paddingTop: 50,
        //paddingLeft:5,
        marginTop: -20,
        marginBottom: -20,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 7,
          },
          content:
            "ECPY Transport and services agreement for Yachts - Version 3.0_10 March 2022",
        },

        {
          type: "text",
          styles: {
            paddingTop: 7,
            fontSize: 12,

            textAlign: "right",
          },
          content: "N° __agreementUniqueNumber__",
        },

        {
          type: "text",
          styles: {
            fontSize: 13,
            marginTop: 15,
            marginBottom: 5,
            textAlign: "center",
            fontFamily: "Times New Roman Bold",
          },
          content: "TRANSPORT AND SERVICES AGREEMENT FOR YACHTS",
        },
      ],
    },

    {
      type: "view",
      styles: {
        paddingTop: 50,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
          },

          content: "__Otheragreementcurrency__ __signDate__ in __signPlace__",
        },

        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
          },

          content: 'BETWEEN __client__ Hereinafter the "Client"',
        },

        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
          },

          content: 'AND __serviceProvider__ Hereinafter the "Service Provider"',
        },

        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
          },

          content: 'Jointly the "Parties"',
        },

        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
          },

          content:
            "In consideration of the mutual undertakings contained herein, the Parties agree to the following",
        },
      ],
    },

    {
      type: "view",
      styles: {
        padding: 0,
        marginBottom: 15,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
            fontFamily: "Helvetica-Bold",
          },
          content: "INFORMATION ABOUT THE VESSEL",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
          },

          items: [
            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Name of Vessel : __vesselName__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Port of Registry : __registryPort__",
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Type : ",
                    },

                    {
                      type: "view",
                      specialType: "choice",
                      linkedField: "yachtType",
                      withPadding: true,
                      styles: {
                        flexDirection: "row",
                      },
                      items: [],
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "IMO number : __imoNumber__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Flag : __flagState__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "Overall length : __yachtLOADinMeters__m ",
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "DETAILS OF THE PARTIES",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Client : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__client__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Address : __clientAddress1__ __clientAddress2__ __clientAddress3__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Service Provider : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__serviceProvider__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Registered Office Address : __serviceProviderAddress1__ __serviceProviderAddress2__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __serviceProviderCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
                display: "flex",
                gap: "10%",
                //justifyContent: "space-between",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Insurance : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __serviceProviderInsuranceReference__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __serviceProviderVATReference__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Broker : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__brokerName__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Registered Office Address :  __brokerAddress1__ __brokerAddress2__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __brokerCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "MYBA Ref : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __brokerMYBAref__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __brokerVATReference__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Central Agent : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__centralAgent__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Registered Office Address : __centralAgentAddress1__ __centralAgentAddress2__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __centralAgentCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "MYBA Ref : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __centralAgentMYBARegistration__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __centralAgentVATReference__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Stakeholder : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "__stakeholder__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Registered Office Address : __stakeholderAddress1__ __stakeholderAddress2__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    "Company registration and country of incorporation : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: " __stakeholderCompanyId__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "MYBA Ref : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderMYBAregistration__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "VAT N° : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderVATreference__",
                    },
                  ],
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },

              items: [
                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Insurance : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderInsuranceReference__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                    width: "50%",
                  },
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "Financial guarantee : ",
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: " __stakeHolderFinancialGuarantee__",
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          type: "view",

          styles: {
            position: "absolute",
            bottom: -70,
            left: 0,
            display: "flex",
            flexDirection: "row",
          },

          items: [
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PLEASE INITIAL",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PROVIDER ______________",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "CLIENT _______________",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: "view",
      items: [
        {
          type: "view",
          attrs: { break: true },
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 50,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "CRUISE PARTICULARS",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content:
                    "From (date and time) : __cruiseStartDate__ __cruiseStartTime__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content:
                    "To (date and time) : __cruiseEndDate__ __cruiseEndTime__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of embarkation : __embarkationPort__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of disembarkation : __disembarkationPort__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of delivery : __deliveryPort__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Port of redelivery : __redeliveryPort__",
                },
              ],
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Cruising area : __cruisingArea__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "Scheduled itinerary cf. Appendix I",
                },
              ],
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Total hours of navigation : __totalHoursOfNavigation__ hours (vessel underway)",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Maximum number of Passengers with staterooms (__paxWithCabinNb__) and day Passengers on board (__dayPaxWithoutCabinNb__)",
            },

            {
              type: "view",
              specialType: "choice",
              linkedField: "fiscalCategoryOfCruise",
              withPadding: true,
              styles: {
                flexDirection: "row",
              },
              items: [],
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "SERVICE PACKAGE PRICE (CLAUSES 12 TO 16)",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Agreement currency : __currencyMain__ ",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Total price for Service Package - Voyage, Services and Accommodation : __totalPriceExclVat__ __currencyMain__ EXCL VAT",
            },

            {
              type: "view",
              styles: {
                flexDirection: "row",
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    width: "50%",
                  },
                  content: "VAT rate : __vatRate__ %",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content: "VAT amount : __vatAmount__ __currencyMain__",
                },
              ],
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Total price for Service Package - Voyage, Services and Accomodation : __totalPriceIncVat__ __currencyMain__ INC VAT",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
              },
              content:
                "Advance provisioning allowance : __advanceProvisioning__ __currencyMain__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
              },
              content:
                "Security deposit : __securityDeposit__ __currencyMain__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                textAlign: "right",
                fontFamily: "Helvetica-Bold",
              },
              content:
                "TOTAL AMOUNT OF PAYMENT : __totalAmountPayment__ __currencyMain__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "TERMS OF PAYMENT",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "First instalment : __firstInstalment__ __currencyMain__ - __firstInstalmentDate__ (amount due / due date)",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Second instalment : __secondInstalment__ __currencyMain__ - __secondInstalmentDate__ (amount due / due date)",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "Third instalment : __thirdInstalment__ __currencyMain__ - __thirdInstalmentDate__ (amount due / due date)",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "BANK TRANSFER DETAILS",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Bank : __bankDesignation__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Account name : __bankAccountName__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "Address : __bankAddress1__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "__bankAddress2__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "IBAN : __bankIban__",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "BIC/SWIFT : __bankBicSwift__",
            },
          ],
        },

        {
          type: "view",
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
              },
              content: "LANGUE / LANGUAGE",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "This Agreement is provided in English, with a non-official version in French being available. In case of a dispute regarding the interpretation of Agreement, the sole English version, included its appendices, is regarded as the legal reference of Agreement.",
            },
          ],
        },

        {
          type: "view",

          styles: {
            position: "absolute",
            bottom: -120,
            left: 0,
            display: "flex",
            flexDirection: "row",
          },

          items: [
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PLEASE INITIAL",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PROVIDER ______________",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "CLIENT _______________",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: "view",
      attrs: { break: true },
      styles: { padding: 30 },
      items: [
        {
          type: "view",

          styles: {
            height: "90vh",
            overflow: "hidden",
            border: "1pt solid #000000",
            padding: 10,
          },
          items: [
            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
                fontFamily: "Helvetica-Bold",
                textAlign: "center",
              },
              content: "SPECIAL CONDITIONS",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "The scope of services is set out in Appendix II and in the special conditions below.",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content:
                "The price for the services listed below is included in the above Price of the Service Package.",
            },

            {
              type: "text",
              styles: {
                fontSize: 10,
                marginTop: 5,
                marginBottom: 5,
              },
              content: "__specialConditions__",
            },
          ],
        },
        {
          type: "view",

          styles: {
            left: 0,
            display: "flex",
            flexDirection: "row",
          },

          items: [
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PLEASE INITIAL",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "PROVIDER ______________",
                },
              ],
            },
            {
              type: "view",

              styles: { width: "30%" },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },

                  content: "CLIENT _______________",
                },
              ],
            },
          ],
        },
      ],
    },

    {
      type: "view",
      attrs: {
        break: true,
      },
      items: [
        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 25,
            marginBottom: 5,
            fontFamily: "Helvetica-Bold",
            textAlign: "center",
          },
          content: "SIGNATURES",
        },

        {
          type: "text",
          styles: {
            fontSize: 10,
            marginTop: 5,
            marginBottom: 5,
          },
          content:
            "The Service Provider and the Client hereby agree that the attached Appendices I (itinerary and Passenger list), II (vessel and scope of services), III (General Sales Conditions, clauses 1 to 29 inclusive), and any/all Special Conditions set out above are an integral part of this Agreement. This Agreement is binding upon signature by the Parties. Signatures provided by fax/email, or in a PDF document sent via email, constitute a legal and enforceable obligation for said signatory having the same force and effect as if this Agreement were an original, irrespective of the original jurisdiction of the Agreement.",
        },

        {
          type: "view",
          styles: {
            flexDirection: "row",
            border: "2pt solid #000000",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "50%",
                border: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "ADDITIONAL INSURANCE",
                },

                {
                  type: "view",
                  specialType: "choice",
                  linkedField: "insuranceOrNo",
                  items: [
                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content: "__insuranceOrNo__",
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    flexDirection: "row",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                        width: "30%",
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "additionalInsuranceLuggage",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            fontSize: 10,
                            marginTop: 5,
                            marginBottom: 5,
                            paddingTop: "3pt",
                          },
                          content: "__additionalInsuranceLuggage__",
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                        width: "30%",
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "additionalInsuranceAssistance",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            fontSize: 10,
                            marginTop: 5,
                            marginBottom: 5,
                            paddingTop: "3pt",
                          },
                          content: "__additionalInsuranceAssistance__",
                        },
                      ],
                    },

                    {
                      type: "view",
                      styles: {
                        ...styles.checkboxWrapper,
                        width: "35%",
                      },
                      items: [
                        {
                          type: "view",
                          specialType: "checkbox",
                          linkedField: "additionalInsuranceCancellation",
                          styles: {
                            ...styles.checkbox,
                          },
                          items: [],
                        },

                        {
                          type: "text",
                          specialType: "display-everytime",
                          styles: {
                            fontSize: 10,
                            marginTop: 5,
                            marginBottom: 5,
                            paddingTop: "3pt",
                          },
                          content: "__additionalInsuranceCancellation__",
                        },
                      ],
                    },
                  ],
                },

                {
                  type: "view",
                  styles: {
                    ...styles.checkboxWrapper,
                    marginTop: "5pt",
                    borderTop: "1pt solid #000000",
                  },
                  items: [
                    {
                      type: "view",
                      styles: {
                        ...styles.checkbox,
                      },
                      items: [],
                    },

                    {
                      type: "text",
                      styles: {
                        fontSize: 10,
                        marginTop: 5,
                        marginBottom: 5,
                      },
                      content:
                        "I hereby declare that I have read, understood and agree to all the terms of this Agreement, its conditions and appendices",
                    },
                  ],
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Client : __client__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Client\'s representative for the signature of this Agreement',
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "SIGNATURE : ____",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "50%",
                border: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Service Provider : __serviceProvider__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Service Provider\'s representative for signature of this Agreement',
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "SIGNATURE : ____",
                },

                {
                  type: "view",
                  styles: {
                    height: "50pt",
                  },
                  items: [],
                },
              ],
            },
          ],
        },

        {
          type: "view",
          styles: {
            marginTop: "3pt",
            border: "1pt solid #000000",
            flexDirection: "row",
          },
          items: [
            {
              type: "view",
              styles: {
                width: "33%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Broker : __brokerName__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: "50pt",
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Broker\'s representative for signature of this Agreement',
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "33%",
                borderRight: "1pt solid #000000",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Central agent : __centralAgent__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: "50pt",
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Central agent\'s representative for signature of this Agreement',
                },
              ],
            },

            {
              type: "view",
              styles: {
                width: "33%",
                padding: 5,
              },
              items: [
                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                  },
                  content: "Stakeholder : __stakeholder__",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    fontFamily: "Helvetica-Bold",
                    borderBottom: "1pt solid #000000",
                  },
                  content: "Date : ",
                },

                {
                  type: "text",
                  styles: {
                    fontSize: 10,
                    marginTop: 5,
                    marginBottom: 5,
                    paddingBottom: "50pt",
                  },
                  content:
                    'Represented by "name, capacity of signatory, full address", duly authorised as the Stakeholder\'s representative for signature of this Agreement',
                },
              ],
            },
          ],
        },
      ],
    },
    /*{
      type: 'view',
      attrs: { break: true },
      items: [
        {
          type: 'view',
          styles: {
            flexDirection: 'row',
          },

          items: [
            {
              type: 'view',
              styles: {
                width: '25%',
              },
              items: [
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    textAlign: 'center',
                    fontWeight: 800,
                  },
                  content: 'APPENDIX III - GENERAL CONDITIONS',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'The following is hereby agreed by the parties. This Transport and Services Agreement, hereby the “Agreement”, describes the provision of a Commercial Service, the “Service Package” by the Service Provider for the benefit of the Client and other passengers. The beneficiaries, including the Client and the persons accompanying the Client, are the “Passengers”.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'The Client hereby agrees that this Agreement shall not constitute the hire of the vessel, nor does it confer the right of ownership or operation in favour of the Client. The Service Provider retains control of the Vessel.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'The provisions of the Agreement may be integrated by special conditions in writing as laid out above. Subject to any special conditions, the Service Package is provided onboad the Vessel and relating equipment.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'The Client is informed about the contractual conditions before entering into the Agreement.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'That fact that the Client is duly informed about the Agreement and agrees to the Service Package in accordance with the terms provided is deemed to constitute the Client’s irrevocable and final agreement to the same.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'Under this Agreement, the Client undertakes to pay the Price of the Service as set out on pages 1 & 2 of the Agreement in accordance with the terms provided in clauses 12 to 16, in particular.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'Before being executed, this Agreement and its Appendices constitute the prior information required under European Union regulations or as these are transposed into national law.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'Furthermore, the Parties acknowledge that the Broker, the Central Agent and the Stakeholder, with regard to their respective involvement in the preparation and performance of this Agreement, are agents designated by the Service Provider, with the status of transparent intermediaries.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'Throughout this Agreement, the terms Provider, Client, Broker, Central Agent and Stakeholder and corresponding pronouns shall be construed to apply whether the Provider, Client, Broker, Central Agent or Stakeholder is male, female, corporate, singular or plural, as the case may be.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontStyle: 'italic',
                    margin: 0,
                  },
                  content:
                    'Furthermore, the Parties agree that the Broker, with regard to its intervention in the preparation and implementation of this Agreement, has the role of introducing the Transporter and the Client and advising the Client on the choice of yacht, its navigation area and the conditions for provision of services aboard, and does not, under any circumstances, sell or offer for sale the transport service nor facilitate the purchase of related services; these are offered, provided and accepted directly between the Transporter and the Client.',
                },
                {
                  type: 'text',
                  styles: {
                    
              fontSize : 10,
               marginTop: 5,
               marginBottom: 5,
                    fontSize: 8,
                    fontWeigth: 800,
                    margin: 0,
                  },
                  content: '1. DEFINITIONS',
                },
                {
                  type: 'text',
                  styles: {},
                  content: 'Central Agent:',
                },
              ],
            },

            {
              type: 'view',
              styles: {
                width: '25%',
              },
              items: [],
            },

            {
              type: 'view',
              styles: {
                width: '25%',
              },
              items: [],
            },

            {
              type: 'view',
              styles: {
                width: '25%',
              },
              items: [],
            },
          ],
        },
      ],
    },*/
  ],
};

export default jsonView;
