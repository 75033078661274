import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";

import { DefaultLayout } from "../_layouts";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.authentication);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("user") ? (
          <>
            {auth &&
              auth.loggedIn &&
              auth.user &&
              auth.user.firstConnection && (
                <Redirect to={{ pathname: "/first-connection" }} />
              )}

            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
