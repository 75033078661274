import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { history } from "../../../_helpers";
import Select from "react-select";

export const CompanyEditForm = ({
  company,
  onSubmit,
  isAddMode = false,
  isUser = false,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: company.name,
    address1: company.address1,
    address2: company.address2,
    zipCode: company.zipCode,
    city: company.city,
    country: company.country,
    MYBAid: company.MYBAid,
    registrationNumber: company.registrationNumber,
    registrationCountry: company.registrationCountry,
    VATnumber: company.VATnumber,
    insurance: company.insurance,
    financialGuarantee: company.financialGuarantee,
    ecpyId: company.ecpyId,
    cdcPermit: company.cdcPermit,
    cdtPermit: company.cdtPermit,
  });

  const {
    name,
    address1,
    address2,
    zipCode,
    city,
    country,
    MYBAid,
    registrationNumber,
    registrationCountry,
    VATnumber,
    insurance,
    financialGuarantee,
    ecpyId,
  } = formData;
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    if (company.cdtPermit === true)
      setSelectedOptions((prevState) => [
        ...prevState,
        { value: "cdtPermit", label: "Transport Agreements" },
      ]);

    if (company.cdcPermit === true)
      setSelectedOptions((prevState) => [
        ...prevState,
        { value: "cdcPermit", label: "Charter Agreements" },
      ]);
  }, [company]);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onSubmit(formData);
  };
  const handleSelectChange = (e) => {
    setSelectedOptions(e);

    setFormData({
      ...formData,
      cdtPermit: e.some((agreement) => agreement.value === "cdtPermit"),
      cdcPermit: e.some((agreement) => agreement.value === "cdcPermit"),
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {!isUser && (
          <Form.Group controlId="companyName">
            <Form.Label>{t("Name")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("Name")}
              required
              name="name"
              value={name}
              onChange={handleChange}
            />
          </Form.Group>
        )}

        <Form.Group controlId="address1">
          <Form.Label>{t("Address 1")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Address 1")}
            required
            name="address1"
            value={address1}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="address2">
          <Form.Label>{t("Address 2")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Address 2")}
            name="address2"
            value={address2}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="zipCode">
          <Form.Label>{t("Zipcode")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Zipcode")}
            required
            name="zipCode"
            value={zipCode}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="city">
          <Form.Label>{t("City")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("City")}
            required
            name="city"
            value={city}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="country">
          <Form.Label>{t("Country")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Country")}
            required
            name="country"
            value={country}
            onChange={handleChange}
          />
        </Form.Group>

        {!isUser && (
          <Form.Group controlId="companyEcpyId">
            <Form.Label>{t("Ecpy ID")}</Form.Label>
            <Form.Control
              type="number"
              placeholder={t("Ecpy ID")}
              required
              name="ecpyId"
              value={ecpyId}
              onChange={handleChange}
            />
          </Form.Group>
        )}

        <Form.Group controlId="MYBAid">
          <Form.Label>{t("MYBAid")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("MYBAid")}
            name="MYBAid"
            value={MYBAid}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="registrationNumber">
          <Form.Label>{t("Registration number")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Registration number")}
            name="registrationNumber"
            value={registrationNumber}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="registrationCountry">
          <Form.Label>{t("Registration country")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Registration country")}
            name="registrationCountry"
            value={registrationCountry}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="VATnumber">
          <Form.Label>{t("VAT number")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("VAT number")}
            name="VATnumber"
            value={VATnumber}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="insurance">
          <Form.Label>{t("Insurance")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Insurance")}
            name="insurance"
            value={insurance}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="financialGuarantee">
          <Form.Label>{t("Financial guarantee")}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("Financial guarantee")}
            name="financialGuarantee"
            value={financialGuarantee}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group /*controlId="contractPermissions"*/>
          <Form.Label>{t("Agreements")}</Form.Label>
          <Select
            //name="react-select-companies"
            options={[
              { value: "cdtPermit", label: "Transport Agreements" },
              { value: "cdcPermit", label: "Charter Agreements" },
            ]}
            value={selectedOptions}
            onChange={handleSelectChange}
            isMulti
            isClearable={true}
            isSearchable={true}
          />

          {/* <Form.Control
            type='select'
            placeholder={t('Choose a type')}
            name='contractPermissions'
            value={contractPermissions}
            onChange={handleChange}
        />*/}
        </Form.Group>
        <Button type="submit" variant="primary">
          {isAddMode ? t("Add") : t("Update")}
        </Button>

        <Button onClick={history.goBack} variant="outline-primary">
          {t("Go back")}
        </Button>
      </Form>
    </>
  );
};
