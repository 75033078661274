import axios from "axios";

import { ApiService } from "./api";

import { authHeader } from "../_helpers";

const apiService = new ApiService();
apiService.setApiUrl("/contract-logs");

export const contractLogsService = {
  add,
  fetchLogsByFolder,
  fetchLatestLogByFolder,
};

async function add(contractFolderId, payload) {
  try {
    const data = JSON.stringify(payload);

    const res = await axios.post(
      `${apiService.apiUrl}/new/${contractFolderId}`,
      data,
      authHeader()
    );
    return res?.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
async function fetchLogsByFolder(contractFolderId) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/${contractFolderId}`,
      authHeader()
    );

    return res?.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}

async function fetchLatestLogByFolder(contractFolderId) {
  try {
    const res = await axios.get(
      `${apiService.apiUrl}/last/${contractFolderId}`,
      authHeader()
    );

    return res?.data;
  } catch (error) {
    throw apiService.manageErrors(error);
  }
}
