import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, Button, Row, Col, Form, Badge, Alert } from "react-bootstrap";

import moment from "moment";
import { CSVLink } from "react-csv";

import { useSelector, useDispatch } from "react-redux";

import { fetchBrokers } from "../../_actions/broker.actions";
import { alertActions } from "../../_actions/alert.actions";
import { alertConstants } from "../../_constants";

import { JddwPagination } from "../../_components";

import { history } from "../../_helpers";

import { brokersService, companiesService } from "../../_services";

import { Link } from "react-router-dom";

import { ValidationModal } from "../../_components";

import "./BrokersList.scss";

export const BrokersList = ({ page = 1, name = "", firstname = "" }) => {
  const brokers = useSelector((state) => state.brokers);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState(false);
  const [toRemoveId, setToRemoveId] = useState(-1);

  const [nameSearch, setNameSearch] = useState(name);
  const [firstnameSearch, setFirstnameSearch] = useState(firstname);

  const [brokersAskingForReset, setBrokersAskingForReset] = useState([]);

  const [csvDatas, setCsvDatas] = useState(null);

  const [brokerIsMain, setBrokerIsMain] = useState(false);

  const csvHeaders = [
    {
      label: t("Id"),
      key: "id",
    },

    {
      label: t("Company ECPY Id"),
      key: "ecpyId",
    },

    {
      label: t("Available tokens"),
      key: "availableTokens",
    },

    {
      label: t("Subscription end date"),
      key: "subscriptionEndDate",
    },
  ];

  useEffect(() => {
    dispatch(fetchBrokers(page, nameSearch, firstnameSearch));
  }, [dispatch, page, name, nameSearch, firstname, firstnameSearch]);

  useEffect(() => {
    const fetchBrokersAskingForReset = async () => {
      try {
        const res = await brokersService.findResetAsked();
        setBrokersAskingForReset(res["hydra:member"]);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    };
    fetchBrokersAskingForReset();
  }, [dispatch]);

  useEffect(() => {
    async function fetchAllBrokers() {
      if (brokers?.items?.length > 0) {
        try {
          const allBrokers = await brokersService.findAllNoPagination();

          let tempBrokers = [];

          for (let broker of allBrokers["hydra:member"]) {
            const brokerObj = {
              id: broker.id,
              ecpyId: broker.company.ecpyId,
              availableTokens:
                broker.company.availableTokens === -1
                  ? t("Illimited")
                  : broker.company.availableTokens,
              subscriptionEndDate: moment(
                broker.company.subscriptionEndDate
              ).format("YYYY-MM-DD"),
            };

            tempBrokers.push(brokerObj);
          }

          setCsvDatas(tempBrokers);
        } catch (err) {}
      }
    }

    fetchAllBrokers();
  }, [brokers, t]);

  const navigateToPage = (page) => {
    history.push(`/admin/brokers/page/${page}`);
  };

  const askToRemoveBroker = async (id) => {
    try {
      const res = await brokersService.find(id);
      const brokerCompany = await companiesService.find(res.company.id);

      if (brokerCompany.mainBroker.id === id) {
        setBrokerIsMain(true);
      }
    } catch (err) {}

    setModalShown(true);
    setToRemoveId(id);
  };

  const removeBroker = async () => {
    let id = toRemoveId;
    if (id !== -1) {
      try {
        const res = await brokersService.remove(id);

        if (res) {
          dispatch(
            alertActions.setAlert(
              "Le broker a bien été supprimé",
              alertConstants.SUCCESS
            )
          );
          dispatch(fetchBrokers(page));
        } else {
          dispatch(
            alertActions.setAlert(
              "Une erreur s'est produite",
              alertConstants.ERROR
            )
          );
        }
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      setToRemoveId(-1);
    }
  };

  const onModalOptionSelected = (option) => {
    if (option === "no") {
      setModalShown(false);
    } else if (option === "yes") {
      removeBroker();
      setModalShown(false);
    }
  };

  const handleNameSearchChange = (e) => {
    setNameSearch(e.target.value);
  };

  const handleFirstnameSearchChange = (e) => {
    setFirstnameSearch(e.target.value);
  };

  return (
    <>
      {brokersAskingForReset.length > 0 && (
        <Alert variant="info" style={{ marginTop: "40px" }}>
          <p>{t("These brokers are asking for a key reset")} : </p>
          {brokersAskingForReset.map((broker) => (
            <p key={broker.id}>
              {broker.firstname} {broker.name} -{" "}
              <a href={`/admin/brokers/${broker.id}`}>
                <i className="fa fa-eye"></i>
              </a>
            </p>
          ))}
        </Alert>
      )}

      <h1 className="page-title">
        <div className="float-right">
          <Link to="/admin/brokers/new">
            <Button variant="primary">{t("Add")}</Button>
          </Link>

          {csvDatas && (
            <CSVLink data={csvDatas} headers={csvHeaders}>
              <Button variant="primary" style={{ marginLeft: "10px" }}>
                {t("CSV export")}
              </Button>
            </CSVLink>
          )}
        </div>

        {t("Broker's list")}
      </h1>

      <ValidationModal
        title={t("Remove broker")}
        text={
          "<p>" +
          t(
            "You're about to remove a broker. Be carefull, this action cannot be undone"
          ) +
          " !</p> <p>" +
          t("Do you wish to confirm ?") +
          "</p>" +
          (brokerIsMain
            ? "<strong>" +
              t("This broker is the main broker of his company") +
              "</strong>"
            : "")
        }
        show={modalShown}
        onOptionSelected={onModalOptionSelected}
      />

      <div className="search">
        <Row>
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder={t("Name")}
              value={nameSearch}
              onChange={handleNameSearchChange}
            />
          </Col>

          <Col md={6}>
            <Form.Control
              type="text"
              placeholder={t("Firstname")}
              value={firstnameSearch}
              onChange={handleFirstnameSearchChange}
            />
          </Col>
        </Row>
      </div>

      <Table bordered hover responsive>
        <thead>
          <tr>
            <th>{t("Active")}</th>
            <th>{t("Welcomed")}</th>
            <th>{t("Firstname")}</th>
            <th>{t("Lastname")}</th>
            <th>{t("Email")}</th>
            <th>{t("Company")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>

        <tbody>
          {brokers.items.map((broker) => (
            <tr key={broker.id}>
              <td>
                {broker.active ? (
                  <Badge pill variant="success">
                    {t("active")}
                  </Badge>
                ) : (
                  <Badge pill variant="warning">
                    {t("deactivated")}
                  </Badge>
                )}
              </td>
              <td>
                {broker.welcomed ? (
                  <Badge pill variant="success">
                    {t("yes")}
                  </Badge>
                ) : (
                  <Badge pill variant="warning">
                    {t("no")}
                  </Badge>
                )}
              </td>
              <td>{broker.firstname}</td>
              <td>{broker.name}</td>
              <td>{broker.email}</td>
              <td>{broker.company.name}</td>
              <td>
                <Link to={"/admin/brokers/" + broker.id}>
                  <i className="brokers-list__icon fas fa-eye"></i>
                </Link>

                <Link to={"/admin/brokers/edit/" + broker.id}>
                  <i className="brokers-list__icon fas fa-edit"></i>
                </Link>

                <i
                  className="brokers-list__icon fas fa-trash"
                  onClick={() => askToRemoveBroker(broker.id)}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {brokers.page && (
        <JddwPagination
          navigateToPage={navigateToPage}
          page={brokers.page}
          nbPages={brokers.nbPages}
        />
      )}
    </>
  );
};
