import React from "react";

import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { LoginForm } from "./LoginForm";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t("Sign in")}</h1>

      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <LoginForm />
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({});

const connectedLoginPage = navigator.cookieEnabled
  ? connect(mapStateToProps)(LoginPage)
  : LoginPage;
export { connectedLoginPage as LoginPage };
