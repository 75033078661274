import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { Row, Col } from "react-bootstrap";

import { fetchLibraryDocs } from "../_actions";

import { JddwPagination, Loader } from "../_components";

import { history, authHeader } from "../_helpers";

import "./Library.scss";

import axios from "axios";

export const LibraryDoc = ({ page = 1 }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const libraryDocs = useSelector((state) => state.libraryDocs);

  const [isLoaderShown, setLoaderShown] = useState(false);

  useEffect(() => {
    dispatch(fetchLibraryDocs(page));
  }, [dispatch, page]);

  const navigateToPage = (page) => {
    history.push(`/notices/${page}`);
  };

  const downloadFile = async (libraryDoc) => {
    setLoaderShown(true);
    let params = authHeader();
    params.responseType = "blob";

    const file = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/download_file/${libraryDoc.id}`,
      params
    );

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(
      new Blob([file.data], { type: "application/octet-stream" })
    );

    const fileDatas = libraryDoc.document.contentUrl.split("/");
    const filename = fileDatas[2];

    link.download = filename;

    document.body.appendChild(link);

    link.click();
    /*     setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200); */
    setLoaderShown(false);
  };

  return (
    <>
      <Loader isShown={isLoaderShown} />

      <div className="libraryDocs-list" style={{ marginTop: "15px" }}>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <h1 className="page-title">{t("Notices")}</h1>

            {libraryDocs.items.map((doc) => (
              <div key={doc.id} className="libraryDocs-list__item">
                <h4 className="libraryDocs__title">{doc.title}</h4>

                <i
                  className="fas fa-file-download libraryDocs__icon"
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(doc);
                  }}
                ></i>
              </div>
            ))}
          </Col>
        </Row>
      </div>

      {libraryDocs.page && (
        <JddwPagination
          navigateToPage={navigateToPage}
          page={libraryDocs.page}
          nbPages={libraryDocs.nbPages}
        />
      )}
    </>
  );
};
