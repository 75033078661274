import React, { useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { UpdatePasswordModal } from "../Broker/modals/UpdatePasswordModal";
import { ValidationModal } from "../_components";

import { Button, Row, Col } from "react-bootstrap";

import { userService, brokersService } from "../_services";
import { alertActions, userActions } from "../_actions";
import { alertConstants } from "../_constants";

import { CompanyInformations } from "../Admin/companies/components/CompanyInformations";

import { Link } from "react-router-dom";

export const MyAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authentication.user);

  const [isPasswordModalShown, setPasswordModalShown] = useState(false);
  const [isKeysModalShown, setKeysModalShown] = useState(false);

  const [userCompany] = useState({});

  const openKeysModal = () => {
    setKeysModalShown(true);
  };

  const onOptionSelected = async (option) => {
    setKeysModalShown(false);

    if (option === "yes") {
      try {
        const updatedBroker = {
          id: user.id,
          resetAsked: true,
        };

        const res = await brokersService.update(updatedBroker);

        dispatch(
          alertActions.setAlert(
            t("Your request has been submitted to the administrator"),
            alertConstants.SUCCESS
          )
        );
        dispatch(userActions.fetchBrokerUser(res));
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const openPasswordModal = () => {
    setPasswordModalShown(true);
  };

  const onModalClose = () => {
    setPasswordModalShown(false);
  };

  const onModalSubmit = async (password) => {
    const updatedUser = {
      id: user.id,
      password: password,
    };

    try {
      await userService.update(updatedUser);

      const updatedBroker = {
        id: user.id,
        passwordUpdated: true,
      };

      await brokersService.update(updatedBroker);

      dispatch(
        alertActions.setAlert(
          t("Your password has been updated"),
          alertConstants.SUCCESS
        )
      );

      dispatch(userActions.fetchBrokerUser(user));

      setPasswordModalShown(false);
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  return (
    <>
      <UpdatePasswordModal
        isShown={isPasswordModalShown}
        handleClose={onModalClose}
        handleSubmit={onModalSubmit}
      />

      <ValidationModal
        title={t("Ask to reset my keys")}
        text={
          "<p>" +
          "<strong>" +
          t("Warning") +
          "</strong> : " +
          t(
            "you will initiate the creation of a new encryption key.This action will revoke your old key, which will no longer be usable. As a result, all the contracts generated by the old key will no longer be accessible. As for the old key, the new key must be kept carefully and securely.  This operation is irrevocable."
          ) +
          "</p><p>" +
          t("Do you wish to continue ?") +
          "</p>"
        }
        show={isKeysModalShown}
        onOptionSelected={onOptionSelected}
      />

      <Row
        style={{
          marginTop: "35px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          borderRadius: "25px",
          padding: "25px",
        }}
      >
        <Col md={8}>
          <h1 className="page-title">{t("My Account")}</h1>

          <h2 className="page-subtitle">
            {user.firstname} {user.name}
            <small style={{ marginLeft: "10px" }}>{user.email}</small>
          </h2>

          <Button onClick={openPasswordModal} variant="success">
            {t("Update my password")}
          </Button>

          {user && !user.resetAsked && (
            <Button
              onClick={openKeysModal}
              variant="primary"
              style={{ marginLeft: "15px" }}
            >
              {t("Reset my crypt keys")}
            </Button>
          )}

          <h2 className="page-title" style={{ marginTop: "50px" }}>
            {t("Your company")}
          </h2>

          <p>
            {t("You're attached to the company")}{" "}
            <strong>{user?.company?.name}</strong> <br />
            {userCompany?.mainBroker?.id === user.id && (
              <>
                <strong>{t("You are the main broker of this company")}</strong>{" "}
                <br />
              </>
            )}
            {t("Available tokens")} :{" "}
            {user?.company?.availableTokens === -1
              ? t("Unlimited")
              : user?.company?.availableTokens}{" "}
            <br />
            {t("Consumed tokens")} : {user?.company?.consumedTokens} <br />
            {t("Subscription end date")} :{" "}
            {moment(user?.company?.subscriptionEndDate).format("YYYY-MM-DD")}
          </p>
        </Col>

        {userCompany?.mainBroker?.id === user.id && (
          <Col
            md={4}
            style={{ borderLeft: "1px solid #a6a5a5", paddingLeft: "50px" }}
          >
            <CompanyInformations company={userCompany} />

            <Link to={"/companies/edit/" + userCompany.id}>
              <Button variant="warning">{t("Update")}</Button>
            </Link>
          </Col>
        )}
      </Row>
    </>
  );
};
