/* External imports */
import React, { useEffect } from "react";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import "@fortawesome/fontawesome-free/css/all.css";

import { connect } from "react-redux";

import axios from "axios";

/* Common tools */
import { history } from "./_helpers";
import { userActions } from "./_actions";
import CookieIcon from "./cookies.svg";
/* Common components */
import { DefaultRoute, PrivateRoute, AdminRoute } from "./_components";

/* Pages */
import { HomePage } from "./HomePage";

import { CompanyEdit } from "./Broker/CompanyEdit";

import moment from "moment";

import {
  AdminDashboard,
  BrokersList,
  BrokersAddEdit,
  BrokerShow,
  CompanyList,
  CompanyShow,
  CompanyAddEdit,
  LibraryIndex,
  ContractIndex,
  ContractTemplateEdit,
  StatsIndex,
  ContractsStats,
} from "./Admin";

import { LoginPage } from "./Auth";

import {
  PageNotFound,
  MyAccount,
  LibraryDoc,
  CheckContract,
  ContactForm,
} from "./Pages";

import { FirstConnection } from "./Broker";

import {
  ContractList,
  ContractEdit,
  ContractFolderList,
  ContractFolderCharterList,
  ContractFolderShow,
} from "./Contract";

/* SCSS */
import "./App.scss";

import { useIdleTimer } from "react-idle-timer";

import { Button, Modal } from "react-bootstrap";
import ForgotPasswordPage from "./Auth/ForgotPasswordPage";
import Terms from "./HomePage/CGV/terms";

/* Axios defaults */
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.delete["Content-Type"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] =
  process.env.REACT_APP_FRONT_URL;

/* Moment locale FR */
moment.locale("fr", {
  months:
    "janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre".split(
      "_"
    ),
  monthsShort:
    "janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.".split("_"),
  monthsParseExact: true,
  weekdays: "dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi".split("_"),
  weekdaysShort: "dim._lun._mar._mer._jeu._ven._sam.".split("_"),
  weekdaysMin: "Di_Lu_Ma_Me_Je_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Aujourd’hui à] LT",
    nextDay: "[Demain à] LT",
    nextWeek: "dddd [à] LT",
    lastDay: "[Hier à] LT",
    lastWeek: "dddd [dernier à] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? "er" : "e");
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === "M";
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? "PD" : "MD";
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
});

const logoutTimeInMn = 30;

const App = ({ dispatch }) => {
  try {
  } catch (error) {}
  const [show, setShow] = React.useState(true);
  useEffect(() => {
    if (navigator.cookieEnabled) {
      if (localStorage.user) {
        let user = JSON.parse(localStorage.user);
        if (user.refresh_token) {
          dispatch(userActions.refreshToken(user.refresh_token));
        }

        history.listen((location, action) => {
          if (localStorage.user) {
            const tokenExpires = localStorage.expiresAt;
            if (!tokenExpires || moment(tokenExpires).isBefore(moment())) {
              dispatch(userActions.refreshToken(user.refresh_token));
            }
          }
        });
      }
    } else {
      history.push("/");
      setShow(true);
    }
  }, [dispatch]);

  const handleOnIdle = (event) => {
    // Logout
    dispatch(userActions.logout());
  };

  useIdleTimer({
    timeout: 1000 * 60 * logoutTimeInMn,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return navigator.cookieEnabled ? (
    <ConnectedRouter history={history}>
      <Switch>
        <DefaultRoute path="/" component={HomePage} exact={true} />
        <DefaultRoute path="/login" component={LoginPage} exact={true} />
        <DefaultRoute
          path="/forgot-password"
          component={ForgotPasswordPage}
          exact={true}
        />
        <DefaultRoute
          path="/check-contract"
          component={CheckContract}
          exact={true}
        />
        <DefaultRoute path="/general-terms" component={Terms} exact={true} />

        <PrivateRoute path="/contact" component={ContactForm} exact={true} />

        {/* Admin routes */}

        <AdminRoute path="/admin" component={AdminDashboard} exact={true} />

        <AdminRoute
          path="/admin/brokers/page/:page/:name?/:firstname?"
          component={(props) => (
            <BrokersList
              page={props.match.params.page}
              name={props.match.params.name}
              firstname={props.match.params.firstname}
              {...props}
            />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/brokers/new"
          component={BrokersAddEdit}
          exact={true}
        />

        <AdminRoute
          path="/admin/brokers/edit/:id"
          component={(props) => (
            <BrokersAddEdit id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/brokers/:id"
          component={(props) => (
            <BrokerShow id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/brokers"
          component={BrokersList}
          exact={true}
        />

        <AdminRoute
          path="/admin/companies/page/:page/:name?"
          component={(props) => (
            <CompanyList
              page={props.match.params.page}
              name={props.match.params.name}
              {...props}
            />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/companies/new"
          component={CompanyAddEdit}
          exact={true}
        />

        <AdminRoute
          path="/admin/companies/edit/:id"
          component={(props) => (
            <CompanyAddEdit id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/companies/:id"
          component={(props) => (
            <CompanyShow id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/companies"
          component={CompanyList}
          exact={true}
        />

        <AdminRoute
          path="/admin/library/:page"
          component={(props) => (
            <LibraryIndex page={props.match.params.page} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/library"
          component={LibraryIndex}
          exact={true}
        />

        <AdminRoute
          path="/admin/contracts/template/:id"
          component={(props) => (
            <ContractTemplateEdit id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/contracts"
          component={ContractIndex}
          exact={true}
        />

        <AdminRoute
          path="/admin/stats/contracts/:page"
          component={(props) => (
            <ContractsStats page={props.match.params.page} {...props} />
          )}
          exact={true}
        />

        <AdminRoute
          path="/admin/stats/contracts"
          component={ContractsStats}
          exact={true}
        />

        <AdminRoute path="/admin/stats" component={StatsIndex} exact={true} />

        {/* Frontoffice routes */}

        <PrivateRoute
          path="/first-connection"
          component={FirstConnection}
          exact={true}
        />

        <PrivateRoute path="/my-account" component={MyAccount} exact={true} />

        <PrivateRoute
          path="/contracts/folders"
          component={ContractFolderList}
          exact={true}
        />

        <PrivateRoute
          path="/contracts/folders/:id"
          component={(props) => (
            <ContractFolderShow id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <PrivateRoute
          path="/charters/folders/:id"
          component={(props) => (
            <ContractFolderShow id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <PrivateRoute
          path="/charters/folders"
          component={ContractFolderCharterList}
          exact={true}
        />

        <PrivateRoute
          path="/contracts/:id"
          component={(props) => (
            <ContractEdit id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <PrivateRoute
          path="/charters/:id"
          component={(props) => (
            <ContractEdit id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <PrivateRoute path="/contracts" component={ContractList} exact={true} />
        {/* <PrivateRoute
          path="/charters/folders"
          component={ContractFolderList}
          exact={true}
          />*/}
        <AdminRoute
          path="/notices/:page"
          component={(props) => (
            <LibraryDoc page={props.match.params.page} {...props} />
          )}
          exact={true}
        />

        <PrivateRoute path="/notices" component={LibraryDoc} exact={true} />

        <PrivateRoute
          path="/companies/edit/:id"
          component={(props) => (
            <CompanyEdit id={props.match.params.id} {...props} />
          )}
          exact={true}
        />

        <DefaultRoute component={PageNotFound} />
      </Switch>
    </ConnectedRouter>
  ) : (
    <Router>
      <DefaultRoute path="/" component={HomePage} exact={true} />
      <DefaultRoute path="/login" component={LoginPage} exact={true} />

      <Modal show={show}>
        <Modal.Body>
          <div className="cookies-consent">
            <img src={CookieIcon} width="75px" alt="Cookie Icon" />
            <span className="cookies-consent-message">
              {" "}
              You need to enable cookies in your browser in order to use ECPY
              Transport Agreement platform.
            </span>
            <Button onClick={() => setShow(false)}>I understand</Button>
          </div>
        </Modal.Body>
      </Modal>
    </Router>
  );
};

export default connect()(App);
