import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Form, Button, Row, Col } from "react-bootstrap";

import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";

import { mediaobjectService, libraryDocsService } from "../../_services";

import { Loader } from "../../_components";

export const LibraryAdd = ({ onAdded }) => {
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [isLoaderShown, setLoaderShown] = useState(false);

  const acceptedFileType = [
    ".doc",
    ".docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".pdf",
    "application/pdf",
    ".zip",
    "application/zip",
    "application/x-zip-compressed",
  ];

  const handleFileChange = (e) => {
    let newFiles = [];

    for (let file of e.target.files) {
      // Check type
      if (acceptedFileType.find((f) => file.type === f)) {
        // Type is OK
        // Check if exists
        if (!selectedFiles.find((f) => f.name === file.name)) {
          newFiles.push(file);
        } else {
          dispatch(
            alertActions.setAlert(
              `Le fichier ${file.name} a déjà été envoyé.`,
              alertConstants.INFO
            )
          );
        }
      } else {
        dispatch(
          alertActions.setAlert(
            `Le fichier ${file.name} ne possède pas le bon format, et a donc été retiré.`,
            alertConstants.ERROR
          )
        );
      }
    }

    setSelectedFiles([...selectedFiles, ...newFiles]);

    setIsFilePicked(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoaderShown(true);

    if (isFilePicked) {
      try {
        for (let file of selectedFiles) {
          const mediaObject = await mediaobjectService.add(file);

          const libraryDoc = {
            title: file.name,
            document: mediaObject["@id"],
          };

          await libraryDocsService.add(libraryDoc);
          onAdded();
        }

        let successMsg = "";
        if (selectedFiles.length > 1) {
          successMsg = "Les documents ont bien été ajoutés";
        } else {
          successMsg = "Le document a bien été ajouté";
        }

        dispatch(alertActions.setAlert(successMsg, alertConstants.SUCCESS));

        setSelectedFiles([]);
      } catch (err) {
        console.error("error : " + err);
      }
    } else {
      dispatch(
        alertActions.setAlert(
          "Merci de bien vouloir ajouter un fichier.",
          alertConstants.ERROR
        )
      );
    }

    setLoaderShown(false);
  };

  return (
    <>
      <Loader isShown={isLoaderShown} />

      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            {/*<Form.Group controlId='docTitle'>
              <Form.Label>Titre du document</Form.Label>
              <Form.Control
                type='text'
                required
                placeholder='Titre'
                name='title'
                value={title}
                onChange={handleChange}
              />
  </Form.Group>*/}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Form.Group
                controlId="docFile"
                style={{ margin: 0, padding: 0, flex: 4 }}
              >
                <Form.File
                  id="docFile"
                  label="Ajouter des documents"
                  custom
                  onChange={handleFileChange}
                  multiple={true}
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,application/pdf,.zip,application/zip,application/x-zip-compressed"
                />

                <Form.Text className="text-muted">
                  {selectedFiles.map((selectedFile, index) => (
                    <span key={index}>
                      {selectedFile.name} <br />
                    </span>
                  ))}
                </Form.Text>
              </Form.Group>

              <Button
                type="submit"
                variant="success"
                style={{ height: "35px" }}
              >
                +
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
};
