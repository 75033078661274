import React, { useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { alertActions, userActions } from "../_actions";
import { alertConstants } from "../_constants";
import { useDispatch } from "react-redux";
import i18n from "../i18n";

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(userActions.resetPassword(email, setIsLoading));
    } else {
      dispatch(
        alertActions.setAlert(
          "Veuillez saisir votre e-mail",
          alertConstants.ERROR
        )
      );
    }
  };
  return (
    <>
      {/*       <Loader isShown={isLoading} />
       */}{" "}
      <Form
        className="d-flex flex-column align-items-center"
        onSubmit={handleSubmit}
      >
        <Row className="mb-3">
          <h3>{i18n.t("Forgot password ?")}</h3>
        </Row>
        <Row>
          <Form.Group controlId="loginEmail" className="loginForm__group">
            <Form.Label className="loginForm__label">
              {i18n.t(
                "Enter your login and we will send you an email containing a new password."
              )}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={i18n.t("Your email address")}
              value={email}
              name="email"
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
        <Row>
          <Button
            variant="primary"
            type="submit"
            className="loginForm__submit"
            disabled={isLoading}
          >
            {isLoading && <Spinner animation="grow" role="status" size="sm" />}
            {` `}
            {i18n.t("Send")}
          </Button>
        </Row>
      </Form>
    </>
  );
}
