import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Table } from "react-bootstrap";

import { fetchContractTemplate } from "../../_actions";

export const ContractTemplateEdit = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const template = useSelector((state) => state.contractTemplates.item);

  useEffect(() => {
    dispatch(fetchContractTemplate(id));
  }, [dispatch, id]);

  return (
    <>
      <h1 className="page-title">{template.name}</h1>

      <h2 className="page-subtitle">{t("Fields")}</h2>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("Name")}</th>
            <th>{t("Type")}</th>
            <th>{t("Label")}</th>
            <th>{t("Required")}</th>
          </tr>
        </thead>

        <tbody>
          {template.fields?.map((field) => (
            <tr key={field.id}>
              <td>{field.name}</td>
              <td>{field.type}</td>
              <td>{field.label}</td>
              <td>{field.required ? t("Yes") : t("No")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
