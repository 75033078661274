import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Container from "react-bootstrap/Container";
import { Alerts } from "../_components";

import { Navbar, Nav } from "react-bootstrap";

import { Link, Redirect, useLocation } from "react-router-dom";

import { LoginForm } from "../Auth/LoginForm";

import { PrivateHeader } from "./private";

import "./DefaultLayout.scss";

import ecpyLogo from "./ecpy.png";
import { GenerateKeysModal } from "../Broker/modals/GenerateKeysModal";
import { brokersService } from "../_services";
import { alertActions, switchLangAction, userActions } from "../_actions";
import { alertConstants } from "../_constants";
import frFlag from "../_components/fr.svg";
import gbFlag from "../_components/gb.svg";

const DefaultLayout = ({ children, dispatch, alert, auth }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const switchLang = (lang) => {
    localStorage.lang = lang;
    i18n.changeLanguage(lang);
    dispatch(switchLangAction(lang));
  };

  const displayAdminNav = () => {
    const user = auth.user;

    const isAdmin = (el) => el === "ROLE_ADMIN";

    if (user.roles?.findIndex(isAdmin) !== -1) {
      return (
        <>
          {t("You're logged in as an administrator")} ({user.email}) <br />
          <Link to="/admin">
            <button variant="primary">{t("Administration dashboard")}</button>
          </Link>
        </>
      );
    } else {
      return (
        <>
          <PrivateHeader />
        </>
      );
    }
  };

  const displayGuestNav = () => {
    return (
      <Container>
        <Navbar variant="dark" expand="lg">
          <Navbar.Toggle aria-controls="private-navbar-nav" />

          <Navbar.Collapse id="private-navbar-nav">
            <Nav>
              <Nav.Link
                as={Link}
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <span>{t("Home")}</span>
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/check-contract"
                className={
                  location.pathname.startsWith("/check-contract")
                    ? "active"
                    : ""
                }
              >
                <span>{t("Check agreement")}</span>
              </Nav.Link>

              {i18n.language === "en" && (
                <a
                  href="https://www.ecpy.org/en_GB/transport-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="nav-link"
                >
                  <span>Transport Agreement</span>
                </a>
              )}

              {i18n.language === "fr" && (
                <a
                  href="https://www.ecpy.org/transport-agreement"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="nav-link"
                >
                  <span>Contrat de Transport</span>
                </a>
              )}

              <a
                href="http://ecpy.org"
                target="_blank"
                class="nav-link"
                rel="noopener noreferrer"
              >
                <span>{t("About ecpy.org")}</span>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    );
  };

  const displayLoginForm = () => {
    return (
      <div className="navbar__login  mt-1">
        <LoginForm />
      </div>
    );
  };
  const onKeysModalSubmit = async () => {
    try {
      const updatedBroker = {
        id: auth.user.id,
        resetAsked: false,
      };

      await brokersService.update(updatedBroker);

      dispatch(
        alertActions.setAlert(
          "Vous pouvez désormais utiliser la plateforme.",
          alertConstants.SUCCESS
        )
      );

      dispatch(userActions.fetchBrokerUser(auth.user));
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }

    //setGenerateKeysModalShown(false);
  };
  return (
    <>
      {/* Redirect if first connection */}
      {auth && auth.loggedIn && auth.user && auth.user.firstConnection && (
        <Redirect to={{ pathname: "/first-connection" }} />
      )}
      {auth.user && auth.user.publicKey === null && auth.user.resetAsked && (
        <GenerateKeysModal
          isShown={auth.user.resetAsked}
          handleSubmit={onKeysModalSubmit}
        />
      )}

      <Alerts />

      <header className="ecpy-header">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "25px",
            left: "25px",
          }}
        >
          <img
            src={ecpyLogo}
            style={{
              width: "60px",
            }}
            alt="ECPY logo"
          />

          <div
            style={{
              borderRight: "2px solid #FFFFFF",
              height: "80px",
              margin: "0 25px",
            }}
          ></div>

          <h1 className="ecpy-header__title">
            {t("ECPY TRANSPORT AND CHARTER AGREEMENTS PLATFORM")}
          </h1>
        </div>
        <div className="flags-wrapper">
          <img
            src={frFlag}
            className="flagIcon"
            onClick={() => switchLang("fr")}
            alt="FR"
          />
          <img
            src={gbFlag}
            className="flagIcon"
            onClick={() => switchLang("en")}
            alt="EN"
          />
        </div>
        <Container fluid>
          <div className="ecpy-header__inside">
            {auth && auth.loggedIn && auth.user && displayAdminNav()}
            {auth && !auth.loggedIn && (
              <>
                {displayGuestNav()}
                {displayLoginForm()}
              </>
            )}
          </div>
        </Container>
      </header>

      <Container>
        <main>{children}</main>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert,
  auth: state.authentication,
});

const connectedLayout = connect(mapStateToProps)(DefaultLayout);
export { connectedLayout as DefaultLayout };
