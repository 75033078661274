import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  item: {},
};

export const contractTemplatesSlice = createSlice({
  name: "contractTemplates",
  initialState,
  reducers: {
    itemsLoaded: (state, action) => {
      return {
        ...state,
        items: action.payload["hydra:member"],
      };
    },

    itemLoaded: (state, action) => {
      return {
        ...state,
        item: action.payload,
      };
    },

    fetchError: (state) => {
      return state;
    },

    empty: (state) => {
      return initialState;
    },
  },
});

export const { itemsLoaded, itemLoaded, fetchError } =
  contractTemplatesSlice.actions;
export default contractTemplatesSlice.reducer;
