import axios from "axios";
import generator from "generate-password";

import { authHeader } from "../_helpers";

const API_URL = `${process.env.REACT_APP_API_ENTRYPOINT}/brokers`;

export const brokersService = {
  findAll,
  find,
  findAllNoPagination,
  add,
  update,
  remove,
  findResetAsked,
  findByEmail,
  sendWelcome,
  sendResetKeyMail
};

function manageErrors(err) {
  if (err.response?.data) {
    const type = err.response.data["@type"];
    if (type && type === "ConstraintViolationList") {
      return err.response.data["hydra:description"];
    } else {
      return err.response.data.message;
    }
  }

  return err;
}

async function findAll(page = 1, name = "", firstname = "") {
  let parameters = `page=${page}`;

  if (name !== "") {
    parameters += `&name=${name}`;
  }

  if (firstname !== "") {
    parameters += `&firstname=${firstname}`;
  }

  try {
    const res = await axios.get(`${API_URL}?${parameters}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function findAllNoPagination() {
  let parameters = `pagination=false`;

  try {
    const res = await axios.get(`${API_URL}?${parameters}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function findResetAsked(page = 1) {
  let parameters = `page=${page}&resetAsked=true`;

  try {
    const res = await axios.get(`${API_URL}?${parameters}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function find(id) {
  try {
    const res = await axios.get(`${API_URL}/${id}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function findByEmail(email) {
  let parameters = `email=${email}`;
  try {
    const res = await axios.get(`${API_URL}?${parameters}`, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function add(email, name, firstname, company, welcomed) {
  // Generate password
  const password = generator.generate({
    length: 10,
    numbers: true,
  });

  try {
    const body = JSON.stringify({
      email,
      password,
      name,
      firstname,
      company,
      welcomed,
    });

    const res = await axios.post(`${API_URL}/new`, body, authHeader());

    return res.data;
  } catch (err) {
    throw manageErrors(err);
  }
}

async function update(broker) {
  try {
    if (broker.companyIRI) {
      broker.company = broker.companyIRI;
    }

    if (broker.ecpyId) {
      broker.ecpyId = parseInt(broker.ecpyId);
    }

    const body = JSON.stringify(broker);
    const res = await axios.put(`${API_URL}/${broker.id}`, body, authHeader());

    if (res.status === 200) {
      return res.data;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}

async function sendWelcome(id) {
  try {
    const res = await axios.get(`${API_URL}/send-welcome/${id}`, authHeader());

    return res.data;
  } catch (err) {
    throw manageErrors(err);
  }
}

async function sendResetKeyMail(id) {
  try {
    const res = await axios.get(
      `${API_URL}/send-mail/reset-key/${id}`,
      authHeader()
    );

    return res.data;
  } catch (err) {
    throw manageErrors(err);
  }
}

async function remove(id) {
  try {
    const res = await axios.delete(`${API_URL}/${id}`, authHeader());

    if (res.status === 204) {
      return true;
    } else {
      throw res.data["hydra:description"];
    }
  } catch (err) {
    throw manageErrors(err);
  }
}
