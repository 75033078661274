import React from "react";

export default function TermsEn() {
  return (
    <>
      <h2 className="terms-title">
        PRIVACY POLICY ECPY TRANSPORT CONTRACT PLATFORM
      </h2>
      <p>
        The platform available on{" "}
        <a
          className="terms-link"
          href="https://contracts.ecpy.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://contracts.ecpy.org/
        </a>{" "}
        is provided by The European Committee for Professional Yachting with
        registered office in the Port of Nice, 06300 Nice, France and is made
        available according to the Terms and Conditions.
      </p>
      <p>
        In order to provide the services on the platform, ECPY must act as a
        Data Controller in accordance with EU regulation 2016/679 GDPR. The data
        transmitted via the platform, using the functions available once you
        have logged in, may be processed under the Data Processing Agreement
        mentioned in the Terms and Conditions.
      </p>
      <p>
        In addition to the data resulting from the contractual relationship
        between the user and ECPY, we are proceeding with the data connectivity
        as well as any other data aimed at the technical and operational
        management of the platform (IP addresses, email, phone number, etc.).
        First-party cookies may be used to manage the connection and the
        maintenance of the active session, the appropriate level of security as
        well as the improvement of the services and the way they are provided.
      </p>
      <p>
        The data shall be stored for the entire duration of the contract, as
        well as for the additional time related to the prescription of rights
        arising from the execution of the contract. You can exercise your rights
        under GDPR at any time. You can ask for access, rectification,
        cancellation, integration of the data concerning you, as well as request
        the restriction of processing; to receive the data in a structured,
        commonly-used and machine-readable format, to object in whole or in part
        to the use of the data, to revoke any consent given as well as to
        exercise the other rights recognized.
      </p>
      <p>
        In accordance with the EU regulation, users have the following rights:
      </p>
      <ul>
        <li>
          Access and rectification, update, completeness of the users’ data
          right lock or deletion of personal users’ data when they are
          inaccurate, incomplete, equivocal, out of date or limit the processing
          of user data (article 15-16-17-18 GDPR)
        </li>
        <li>Withdraw consent at any time (article 13-2c GDPR)</li>
        <li>
          Oppose the processing and automation of user data and based on their
          consent/contract (article 20-21 GDPR)
        </li>
        <li>
          Define the fate of the data of the users after their death and to
          choose to whom we will have to communicate, or not, its data to a
          third party they have previously designated or destroy his data unless
          their retention is necessary for probative purposes or to fulfill a
          legal obligation.
        </li>
      </ul>
      <p>
        We may update our Privacy Policy on a regular basis. We will notify you
        of any changes by posting updates on this page and advice you to review
        this policy periodically for any changes.
      </p>
      <p>
        To contact us, you can write to:{" "}
        <a className="terms-link" href="mailto:info@ecpy.org">
          info@ecpy.org
        </a>
      </p>
      <h2 className="terms-title">PRIVACY POLICIES AND COOKIES</h2>
      <p>
        This document provides information on the processing of personal data
        collected through this website and consequently constitutes information
        for the people concerned in compliance with the applicable regulations
        on personal data protection. The disclosure is in accordance with EU
        regulation 2016/679 and only concerns this present website{" "}
        <a
          className="terms-link"
          href="https://contracts.ecpy.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://contracts.ecpy.org/
        </a>
      </p>
      <p>
        The website (
        <a
          className="terms-link"
          href="https://ecpy.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ecpy.org
        </a>
        ) is hosted and created by means of Odoo platform. Some services are
        provided by MailChimp, a registered trademark by The Rocket Science
        Group LLC.
      </p>
      <p>The Transport Agreement Platform is created</p>
      <h2 className="terms-title">JOINT CONTROLLER</h2>
      <p>
        The European Committee for Professional Yachting with registered offices
        in Nice – 06300 Nice – Quai Amiral Infernet.
      </p>
      <h2 className="terms-title">WHAT DATA ARE PROCESSED?</h2>
      <p>During your navigation on the pages of this website, we collect:</p>
      <ul>
        <li>
          Connection data: personal data required for the working of the
          website, such as IP addresses or domain names of computers used by
          users who connect to the site,
        </li>
        <li>
          URI addresses (Uniform Resource Identifier) of requested resources,
          the time of the request, the method utilized to submit the request to
          the server, the size of the file obtained in reply, the numerical code
          indicating the status of the response from the server and other
          parameters related to the operating system and the information
          environment of the users, the information related to the behavior on
          the websites, e.g using cookies.
        </li>
      </ul>
      <p>
        Cookies are small text files that the website places on the devices
        used, whether they are computers or mobile devices, saved within the
        directory files used by the web browser of the user. There are several
        types of cookies, some to make the use of the use of the website more
        efficient, other to enable certain functions. They may also be further
        cookies and pixed tracking placed in the services and apps used by the
        website (e.g maps, social sharing, newsletters, etc).
      </p>
      <h2 className="terms-title">FOR WHAT PUROPOSES?</h2>
      <ul>
        <li>
          Contractual purposes: to look at the webpages and use the services
          provided on the website{" "}
          <a
            className="terms-link"
            href="https://www.ecpy.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.ecpy.org/
          </a>
          .
        </li>
        <li>
          Legal obligations: to meet legal obligations in compliance with
          applicable regulations and national and supranational legislation.
        </li>
        <li>
          How the website works: during the course of their regular functions,
          computing systems and software procedure at the basis of the
          functioning of website acquire data whose transmission is implicit in
          the use of internet communication protocols. The purpose for the
          collection of such information is not to be associated with
          identifiable subjects but may through processing and matching with
          other data held by the joint controller or by third subjects, allow
          the identification of the users of the websites.
        </li>
        <li>
          Communicating with the user upon requests: data transmitted through
          the entirely optional, explicit, and voluntary sending of email
          messages to the addresses specified on this website shall mean the
          consequent acquisition of the user’s email address, necessary to reply
          to his/her requests, and of any other personal data included in the
          message by the user.
        </li>
        <li>
          Communicating with the user for marketing purposes: collected data are
          used for marketing purposes by sending – through automatic contacting
          procedures (such as sms, mms and emails) as well as traditional ones –
          promotional and commercial information connected to services and/or
          products or to advertise company events or the carrying out of market
          and statistical analysis. The communications may also refer to
          marketing activities organized for third party.
        </li>
        <li>
          Newsletter: will sent only upon your consent and explicit subscription
          to such service.
        </li>
        <li>
          Provide and maintain the service, to notify you about any changes to
          our service and even to allow you to participate in interactive
          features of our services when you choose to do so
        </li>
        <li>
          Provide customer care and support, detect, prevent, and address
          technical issues.
        </li>
      </ul>
      <p>
        The person concerned must express his/her consent to the treatment of
        personal data for specific purposes.{" "}
      </p>
      <h2 className="terms-title">DATA COLLECTION & PROCESSING</h2>
      <p>Data collected through the website are kept:</p>
      <ul>
        <li>
          In the event of treatments for contractual purposes and compliance of
          legal requirements: for the whole duration of the service/membership
          and after its termination for a period not exceeding 10 years.
        </li>
        <li>
          In the event of treatments for marketing purposes: until the
          revocation of the expressed consent by the person concerned for such
          purposes.
        </li>
        <li>
          In the event of treatments required for the functioning of the
          website: for the whole duration of the navigation session on the
          websites and for the longest period of analysis, in any case for a
          period not exceeding 12 months.
        </li>
      </ul>
      <p>
        After the mentioned conservation time limits, your personal data shall
        be destroyed, deleted, or made anonymous, in compliance with the
        technical deletion and backup procedures.{" "}
      </p>
      <p>
        Your personal data may be shared, when strictly necessary for the
        indicated purposes, with:
      </p>
      <ul>
        <li>
          Third party specifically appointed by the joint controller to process
          personal data, necessary to the carrying out of activities strictly
          connected to the supply of the services,
        </li>
        <li>
          Third party providing services to companies, acting typically as
          subjects in charge of the treatment, such as managers of communication
          services, email, delivery of mail, technical services for the
          management/maintenance of the service or other providers of
          information services.
        </li>
        <li>
          Companies providing support during the carrying out of market surveys.
        </li>
      </ul>
      <h2 className="terms-title">WHAT RIGHTS CAN BE EXERTED?</h2>
      <p>
        It is possible to exert at any time the rights as specified in the EU
        regulation 679/2016. More specifically, the rights to request to the
        joint controller the access to date concerning the user, their amendment
        or cancellation, the integration of incomplete data, the limitation or
        the treatment; to request data provided in a structured format of common
        use and readable by an automatic device; to revoke the agreed consent
        regarding the treatment and to oppose, partially or entirely, to the
        usage of data; and to exert other rights granted by the applicable rules
        and regulations. In the event the user believes that any of the
        treatments breaks the current regulation on personal data treatment, he
        can contact us to solve the problem.{" "}
      </p>
      <p>
        ome functions of the website enable to exercise one’s rights directly
        through internet access, as for instance with the newsletter. For
        further information on the exertion of your rights it is possible to
        contact us by email at the following address:{" "}
        <a className="terms-link" href="mailto:info@ecpy.org">
          info@ecpy.org
        </a>
        .{" "}
      </p>
      <h2 className="terms-title">COOKIE POLICY</h2>
      <p>
        The European Committee for Professional Yachting website
        https://www.ecpy.org/ uses cookies to ensure the correct functioning of
        the procedures and improve the use of online applications. This document
        gives detailed information on cookies and their use and of similar
        technologies, and how to manage them. This document is integrated to the
        complete notice on personal data treatment on our website.{" "}
      </p>
      <p>
        Cookies are small text files created by a web-based application and
        saved the user’s chosen client to browse the internet (pc, smartphone,
        tablet) to be sent during the following accesses to the internet by the
        same user. Cookies enable the collection of information on the user’s
        browsing, for instance to remember the preferences on the language or
        the currency used for a purchase. Cookies may be permanently saved on a
        device and be set to a variable duration (e.g persistent cookies), but
        can be erased when the user closes the web browser to be set to a
        limited duration (e.g session cookies). Cookies can be installed by the
        website you are browsing (first-party cookies) or by other websites
        (third-party cookies). A good example is represented by the presence of
        “social plugins” for Instagram, Facebook, LinkedIn and YouTube. They are
        parts of the visited website generated directly by the websites and
        integrated within the page of the host-website. The presence of the
        plugins means the transmission of cookies to and from all third-party
        websites.{" "}
      </p>
      <p>
        For further transparency and convenience, you can find below the web
        address of the different notices and ways to manage cookies:
      </p>
      <p>
        <a
          className="terms-link"
          href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0
        </a>
      </p>
      <p>
        <a
          className="terms-link"
          href="https://fr.linkedin.com/legal/cookie-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://fr.linkedin.com/legal/cookie-policy
        </a>
      </p>
      <p>
        <a
          className="terms-link"
          href="https://www.facebook.com/help/instagram/1896641480634370"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/help/instagram/1896641480634370
        </a>
      </p>
      <p>
        <a
          className="terms-link"
          href="https://www.youtube.com/intl/ALL_fr/howyoutubeworks/our-commitments/protecting-user-data/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.youtube.com/intl/ALL_fr/howyoutubeworks/our-commitments/protecting-user-data/
        </a>
      </p>
      <p>
        Depending on the characteristics and the use of cookies, we cand
        distinguish different categories:
      </p>
      <ul>
        <li>
          Strictly-necessary cookies: these are crucial cookies for the correct
          functioning of the website, and are used to manage session, log-in and
          access to the functions reserved to the website. The duration of the
          cookies is strictly limited to the work session (they are cancelled
          once the web browser is closed).
        </li>
        <li>
          Cookies for analysis and performances: these cookies are used to
          collect and analyze traffic and website usage. These cookies can
          detect, for instance, if the same user goes back online at different
          moments. They can also monitor the system and improve its performance
          and usability. Disabling these cookies won’t mean a loss of feature.
        </li>
        <li>
          Profiling cookies: these are cookies used to profile the user’s
          preferences and improve his/her experience on the web, as well as
          combining it to other information.
        </li>
      </ul>
      <p>
        The website includes also some components transmitted by Google
        Analytics, a web service offered by Google. In this case, these
        third-party cookies are collected and managed anonymously to monitor and
        improve the performances of the host-website. Google Analytics uses
        cookies to collect and anonymous analyze information on the behavior of
        website usage (including the users’ IP address). These information are
        processed and gathered by Google Analytics to draw up reports for the
        operators regarding the activities on the website. Google does not
        associate the IP addresses to other data held by Google, nor tries to
        match an IP addresses with the user’s identity. For further information,
        please visit https://policies.google.com/technologies/cookies?hl=en-US .{" "}
      </p>
      <p>
        The first-party and third-party cookies used by the website aim to
        identify unique visitors and tracks a visitor’s session on the website,
        remember if a visitor agreed to placing analytics cookies on their
        browser if a site is restricting the placement of cookies, to identify
        unique visitors to our website, manage and improvement the management of
        our platform, and manage the subscription of newsletters.
      </p>
      <p>
        The user can decide whether to accept cookies or not by using his/her
        browser’s setting.
      </p>
      <p>
        Attention: a partial or total disabling of the technical cookies may
        compromise the use of the website’s functions. On the contrary, the
        usability of public contents is possible even disabling cookies
        completely. Disabling third-party cookies doesn’t affect the browsing
        experience in any way. To disable cookies, you have the possibility to
        use anonymous browser or incognito mode or disable cookies through your
        web browser and third-party services. However, if the user chooses to
        disable website cookies partially or totally, or some parts, or all the
        services provided by third parties, some functions and performances of
        our website may be reduced and/or unavailable.{" "}
      </p>
      <p>
        Cookies settings can be defined specifically according to the different
        websites and web applications. You will have the possibility to receive
        further information by email by contacting us at{" "}
        <a className="terms-link" href="mailto:info@ecpy.org">
          info@ecpy.org
        </a>
        .
      </p>
    </>
  );
}
