import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { CSVLink } from "react-csv";
import moment from "moment";

import { fetchBroker, fetchCompany, alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";

import { Button, Row, Col, Badge, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import { history } from "../../_helpers";

import generator from "generate-password";

import { brokersService, contractFoldersService } from "../../_services";

import { ValidationModal } from "../../_components";

import "./BrokerShow.scss";

export const BrokerShow = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const broker = useSelector((state) => state.brokers.current);
  const company = useSelector((state) => state.companies.item);

  const [modalShown, setModalShown] = useState(false);

  const [csvDatas, setCsvDatas] = useState(null);

  const csvHeaders = [
    {
      label: t("Id"),
      key: "id",
    },

    {
      label: t("Company ECPY Id"),
      key: "ecpyId",
    },

    {
      label: t("Available tokens"),
      key: "availableTokens",
    },

    {
      label: t("Subscription end date"),
      key: "subscriptionEndDate",
    },
  ];

  useEffect(() => {
    dispatch(fetchBroker(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (broker?.company?.id) {
      dispatch(fetchCompany(broker?.company?.id));
    }

    if (broker?.company?.ecpyId) {
      setCsvDatas([
        {
          id: broker?.id,
          ecpyId: broker?.company?.ecpyId,
          availableTokens:
            broker?.company?.availableTokens === -1
              ? t("Illimited")
              : broker?.company?.availableTokens,
          subscriptionEndDate: moment(
            broker?.company?.subscriptionEndDate
          ).format("YYYY-MM-DD"),
        },
      ]);
    }
  }, [dispatch, t, broker]);

  const switchActive = async () => {
    await brokersService.update({
      id: broker.id,
      active: !broker.active,
    });

    dispatch(
      alertActions.setAlert(
        t("The broker has been updated"),
        alertConstants.SUCCESS
      )
    );

    dispatch(fetchBroker(id));
  };

  const askToGenerateNewKey = () => {
    setModalShown(true);
  };

  const onModalOptionSelected = (option) => {
    if (option === "no") {
      setModalShown(false);
    } else if (option === "yes") {
      generateNewKey();
      setModalShown(false);
    }
  };

  const generateNewKey = async () => {
    try {
      await brokersService.update({
        id: broker.id,
        publicKey: null,
        firstConnection: false,
        resetAsked: true,
      });

      await brokersService.sendResetKeyMail(broker.id);
      // Remove contracts
      const contractsRes =
        await contractFoldersService.findByBrokerViaApiPlatform(broker.id);
      const contracts = contractsRes["hydra:member"];

      for (let contract of contracts) {
        await contractFoldersService.remove(contract.id);
      }

      dispatch(
        alertActions.setAlert(
          t("Broker's keys has been resetted"),
          alertConstants.SUCCESS
        )
      );

      dispatch(fetchBroker(id));
    } catch (err) {
      dispatch(
        alertActions.setAlert(t("Error") + " : " + err, alertConstants.ERROR)
      );
    }
  };

  const resetPassword = async () => {
    // Generate password
    const password = generator.generate({
      length: 10,
      numbers: true,
    });

    try {
      await brokersService.update({
        id: broker.id,
        plainPassword: password,
      });

      dispatch(
        alertActions.setAlert(
          t("Broker's password has been resetted"),
          alertConstants.SUCCESS
        )
      );
    } catch (err) {
      dispatch(
        alertActions.setAlert(t("Error") + " : " + err, alertConstants.ERROR)
      );
    }
  };

  const sendWelcome = async () => {
    // Generate password
    const password = generator.generate({
      length: 10,
      numbers: true,
    });

    try {
      await brokersService.update({
        id: broker.id,
        plainPassword: password,
        welcomedAsked: true,
      });

      await brokersService.update({
        id: broker.id,
        welcomedAsked: false,
        welcomed: true,
      });

      dispatch(fetchBroker(broker.id));

      dispatch(
        alertActions.setAlert(
          t("Welcome email has been sent"),
          alertConstants.SUCCESS
        )
      );
    } catch (err) {
      dispatch(
        alertActions.setAlert(t("Error") + " : " + err, alertConstants.ERROR)
      );
    }
  };

  return (
    <>
      <ValidationModal
        title={t("Reset broker's keys")}
        text={
          "<p>" +
          t(
            "WARNING ! After resetting the keys, the broker will be prevented from accessing his contracts and documents. You can't cancel this action."
          ) +
          "</p>" +
          "<p>" +
          t("Do you wish to continue ?") +
          "</p>"
        }
        show={modalShown}
        onOptionSelected={onModalOptionSelected}
      />

      <div className="broker">
        <h2 className="page-title broker__title">
          <div className="float-right">
            {csvDatas && (
              <CSVLink data={csvDatas} headers={csvHeaders}>
                <Button variant="primary">{t("CSV export")}</Button>
              </CSVLink>
            )}
          </div>
          {broker.firstname} {broker.name}
          <small className="broker__status">
            {broker.active ? (
              <Badge pill variant="success">
                {t("active")}
              </Badge>
            ) : (
              <Badge pill variant="warning">
                {t("deactivated")}
              </Badge>
            )}
          </small>
        </h2>

        {broker.resetAsked && (
          <Alert variant="danger">
            <p>{t("This broker is asking for a key reset")}</p>
          </Alert>
        )}

        <div className="broker__actionsBtns">
          <Button
            variant="info"
            onClick={switchActive}
            size="sm"
            className="broker__switchBtn"
          >
            {broker.active ? t("De-activate account") : t("Activate account")}
          </Button>

          {!broker.welcomed && (
            <Button
              variant="warning"
              onClick={sendWelcome}
              size="sm"
              className="broker__switchBtn"
              style={{ marginLeft: "10px" }}
            >
              {t("Send welcome email")}
            </Button>
          )}

          <Button
            variant="danger"
            onClick={askToGenerateNewKey}
            size="sm"
            className="broker__switchBtn"
            style={{ marginLeft: "10px" }}
          >
            {t("Reset broker keys")}
          </Button>

          <Button
            variant="danger"
            onClick={resetPassword}
            size="sm"
            className="broker__switchBtn"
            style={{ marginLeft: "10px" }}
          >
            {t("Reset password")}
          </Button>
        </div>

        <Row>
          <Col md={5}>
            <div className="broker__informations">
              <h3 className="page-subtitle">{t("Informations")}</h3>

              <p>
                <strong>{t("Email")}</strong> : {broker.email}
              </p>

              <p>
                <strong>{t("Company")}</strong> :
                <Link to={"/admin/companies/" + broker.company?.id}>
                  {broker.company?.name}
                </Link>
                {company?.mainBroker?.id === broker.id && (
                  <>
                    <br />
                    <strong>
                      {t("This broker is the main broker of the company")}
                    </strong>
                  </>
                )}
              </p>
            </div>
          </Col>

          {/*<Col md={7}>
            <h3 className='page-subtitle'>{t('Statistics')}</h3>
                </Col>*/}
        </Row>
      </div>

      <div className="broker__btns">
        <Link to={"/admin/brokers/edit/" + broker.id}>
          <Button variant="warning">{t("Update")}</Button>
        </Link>

        <Button onClick={history.goBack} variant="outline-primary">
          {t("Back to folder")}
        </Button>
      </div>
    </>
  );
};
