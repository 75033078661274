import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { companiesService } from "../_services";
import { alertActions } from "../_actions";
import { alertConstants } from "../_constants";

import { CompanyEditForm } from "../Admin/companies/components/CompanyEditForm";

export const CompanyEdit = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [company, setCompany] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const company = await companiesService.find(id);

        setCompany(company);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    })();
  }, [dispatch, id]);

  const handleSubmit = async (formData) => {
    try {
      formData.id = company.id;
    } catch (err) {
      dispatch(alertActions.setAlert(err, alertConstants.ERROR));
    }
  };

  return (
    <>
      <h1 className="page-title">{t("Update company")}</h1>

      {company && (
        <CompanyEditForm
          company={company}
          onSubmit={handleSubmit}
          isAddMode={false}
          isUser={true}
        />
      )}
    </>
  );
};
