import React from "react";
import { Container } from "react-bootstrap";
import TermsEn from "./terms-en";
import TermsFr from "./terms-fr";
import "./terms.scss";
import { useTranslation } from "react-i18next";

export default function Terms() {
  const { i18n } = useTranslation();

  return (
    <Container className="terms-container">
      {i18n.language === "fr" ? <TermsFr /> : <TermsEn />}
    </Container>
  );
}
