import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  item: {},
  lang: localStorage.getItem("lang"),
};

export const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    itemsLoaded: (state, action) => {
      return {
        ...state,
        items: action.payload["hydra:member"],
      };
    },

    itemLoaded: (state, action) => {
      return {
        ...state,
        item: action.payload,
      };
    },

    fetchError: (state) => {
      return state;
    },

    empty: (state) => {
      return initialState;
    },

    langLoaded: (state, action) => {
      return {
        ...state,
        lang: action.payload,
      };
    },
  },
});

export const { itemsLoaded, itemLoaded, fetchError } = contractsSlice.actions;
export default contractsSlice.reducer;
