import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Table, Button } from "react-bootstrap";

import moment from "moment";
import "moment-duration-format";

import { CSVLink } from "react-csv";

import { history } from "../../_helpers";

import { fetchContractsHistories } from "../../_actions";

import { JddwPagination } from "../../_components";

export const ContractsStats = ({ page = 1 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const histories = useSelector((state) => state.contractsHistories);
  const [historiesForCsv, setHistoriesForCsv] = useState(null);

  const [mediumYachtLength, setMediumYachtLength] = useState(0);

  const csvHeaders = [
    {
      label: t("Agreement unique number"),
      key: "agreementUniqueNumber",
    },

    {
      label: t("Duration"),
      key: "duration",
    },

    {
      label: t("Yacht Length"),
      key: "yachtLength",
    },

    {
      label: t("Cruising area"),
      key: "cruisingArea",
    },
  ];

  useEffect(() => {
    dispatch(fetchContractsHistories(page));
  }, [dispatch, page]);

  useEffect(() => {
    let tempHistories = [];

    let yachtLength = 0;
    let nbItems = 0;

    for (let history of histories.items) {
      tempHistories.push({
        agreementUniqueNumber: history.agreementUniqueNumber,
        duration: moment.duration(history.duration).format("D") + t("days"),
        yachtLength: history.yachtLength,
        cruisingArea: history.cruisingArea,
      });

      yachtLength += history.yachtLength;
      nbItems++;
    }

    if (nbItems > 0) {
      yachtLength = yachtLength / nbItems;
      setMediumYachtLength(yachtLength);
    }

    setHistoriesForCsv(tempHistories);
  }, [histories.items, t]);

  const navigateToPage = (page) => {
    history.push(`/admin/stats/contracts/${page}`);
  };

  return (
    <>
      <h1 className="page-title">
        <div className="float-right">
          {historiesForCsv && (
            <CSVLink data={historiesForCsv} headers={csvHeaders}>
              <Button variant="primary">{t("CSV export")}</Button>
            </CSVLink>
          )}
        </div>

        {t("Contract's stats")}
      </h1>

      <strong>
        {t("Medium yacht length")} : {mediumYachtLength}
      </strong>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>{t("Broker")}</th>
            <th>{t("Agreement unique number")}</th>
            <th>{t("Duration")}</th>
            <th>{t("Yacht length")}</th>
            <th>{t("Cruising area")}</th>
          </tr>
        </thead>

        <tbody>
          {histories?.items?.map((history) => (
            <tr key={history.id}>
              <td>{history.broker.email}</td>
              <td>{history.agreementUniqueNumber}</td>
              <td>
                {moment.duration(history.duration).format("D")} {t("days")}
              </td>
              <td>{history.yachtLength}</td>
              <td>{history.cruisingArea}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {histories.page && (
        <JddwPagination
          navigateToPage={navigateToPage}
          page={histories.page}
          nbPages={histories.nbPages}
        />
      )}
    </>
  );
};
