import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  item: {},
  fetched: false,
};

export const contractFoldersSlice = createSlice({
  name: "contractFolders",
  initialState,
  reducers: {
    itemsLoaded: (state, action) => {
      return {
        ...state,
        items: action.payload,
        fetched: true,
      };
    },

    itemLoaded: (state, action) => {
      return {
        ...state,
        item: action.payload,
      };
    },
    fetchError: (state) => {
      return state;
    },

    empty: (state) => {
      return initialState;
    },
  },
});

export const { itemsLoaded, itemLoaded, fetchError } =
  contractFoldersSlice.actions;
export default contractFoldersSlice.reducer;
