import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { companiesService } from "../../_services";
import { alertActions } from "../../_actions";
import { alertConstants } from "../../_constants";

import { history } from "../../_helpers";

import { CompanyEditForm } from "./components/CompanyEditForm";

export const CompanyAddEdit = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAddMode = !id;

  const [company, setCompany] = useState(null);

  useEffect(() => {
    (async () => {
      if (!isAddMode) {
        try {
          const company = await companiesService.find(id);
          setCompany(company);
        } catch (err) {
          dispatch(alertActions.setAlert(err, alertConstants.ERROR));
        }
      }
    })();
  }, [dispatch, id, isAddMode]);

  const handleSubmit = async (formData) => {
    const {
      name,
      address1,
      address2,
      zipCode,
      city,
      country,
      MYBAid,
      registrationNumber,
      registrationCountry,
      VATnumber,
      insurance,
      financialGuarantee,
      ecpyId,
      cdcPermit,
      cdtPermit,
    } = formData;

    if (isAddMode) {
      try {
        await companiesService.add(
          name,
          address1,
          address2,
          zipCode,
          city,
          country,
          MYBAid,
          registrationNumber,
          registrationCountry,
          VATnumber,
          insurance,
          financialGuarantee,
          parseInt(ecpyId),
          cdcPermit,
          cdtPermit
        );

        dispatch(
          alertActions.setAlert(
            t("New company has been added"),
            alertConstants.SUCCESS
          )
        );

        history.push("/admin/companies");
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    } else {
      // Edit
      try {
        formData.id = company.id;
        await companiesService.editCompany(id, formData);

        dispatch(
          alertActions.setAlert(
            "La société a bien été modifiée.",
            alertConstants.SUCCESS
          )
        );

        history.push("/admin/companies/" + id);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  return (
    <>
      <h1 className="page-title">
        {isAddMode ? "Ajouter une société" : "Modifier une société"}
      </h1>

      {isAddMode && (
        <CompanyEditForm
          company={{}}
          onSubmit={handleSubmit}
          isAddMode={isAddMode}
        />
      )}

      {!isAddMode && company && (
        <CompanyEditForm
          company={company}
          onSubmit={handleSubmit}
          isAddMode={isAddMode}
        />
      )}
    </>
  );
};
