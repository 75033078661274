import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Alert, Form, Button } from 'react-bootstrap'

import { fetchPrivateKey, storePrivateKey } from '../../_actions'

export const PrivateKey = () => {
  const dispatch = useDispatch()

  const privateKey = useSelector((state) => state.cryptKeys.privateKey)
  const [tempPrivateKey, setTempPrivateKey] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchPrivateKey())
  }, [dispatch])

  const updatePrivateKey = (e) => {
    setTempPrivateKey(e.target.value)
  }

  const validatePrivateKey = () => {
    dispatch(storePrivateKey(tempPrivateKey))
  }

  const switchToEditMode = () => {
    // Remove session storage key
    sessionStorage.removeItem('privateKey')
    setTempPrivateKey('')
    dispatch(fetchPrivateKey())
  }

  return (
    <Alert
      variant='info'
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '15px',
      }}
    >
      {privateKey === '' ? (
        <>
          <p>{t('To view or add contracts, please set your private key')}</p>

          <Form.Group controlId='brokerPrivateKey'>
            <Form.Control
              type='text'
              placeholder={t('Your private key')}
              value={tempPrivateKey}
              onChange={updatePrivateKey}
            />

            <Button
              variant='success'
              onClick={validatePrivateKey}
              style={{ marginTop: '15px' }}
            >
              {t('Save')}
            </Button>
          </Form.Group>
        </>
      ) : (
        <p style={{ margin: 0 }}>
          {t('Your private key')} {t('is set')}
          <Button
            variant='secondary'
            size='sm'
            onClick={switchToEditMode}
            style={{ marginLeft: '15px' }}
          >
            {t('Update')}
          </Button>
        </p>
      )}
    </Alert>
  )
}
