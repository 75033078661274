import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";

import { routerMiddleware } from "connected-react-router";
import { history } from "./history";

import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "../_reducers";
import { createLogger } from "redux-logger";

const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), thunk, loggerMiddleware)
  )
);
