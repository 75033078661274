import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Badge,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";

import {
  emptyContractFolder,
  fetchContractFoldersByBroker,
} from "../_actions/contractFolders.actions";

import { alertActions } from "../_actions";
import { alertConstants } from "../_constants";
import moment from "moment";

import {
  contractFoldersService,
  brokersService,
  contractFieldValuesService,
  contractsService,
} from "../_services";

import { encrypt, decrypt, getPublicKey } from "../_helpers";

import { PrivateKey } from "./_components/PrivateKey";

import { Link } from "react-router-dom";

import { ValidationModal } from "../_components";

import { Loader } from "../_components";
import { createDocumentObj } from "./common/utils";
import { Document, pdf } from "@react-pdf/renderer";
import { ContractDocument } from "./ContractDocument";
import { contractLogsService } from "../_services/contract.logs.service";

export const ContractFolderList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state.authentication.user);
  const contractFolders = useSelector((state) => state.contractFolders);

  const [isTitleModalShown, setTitleModalShown] = useState(false);
  const [isShareModalShown, setShareModalShown] = useState(false);
  const [isDuplicateModalShown, setDuplicateModalShown] = useState(false);
  const [isDeleteModalShown, setDeleteModalShown] = useState({
    status: null,
    show: false,
  });

  const [isLoaderShown, setLoaderShown] = useState(true);

  const [formData, setFormData] = useState({
    name: "",
    lang: "en",
  });

  const [shareData, setShareData] = useState({
    shareEmail: "",
  });

  const { name, lang } = formData;
  const { shareEmail } = shareData;

  const [currentSharedId, setCurrentSharedId] = useState(0);
  const [currentDeleteId, setCurrentDeleteId] = useState(0);
  const [currentDpulicateId, setcurrentDpulicateId] = useState(0);
  const privateKey = useSelector((state) => state.cryptKeys.privateKey);

  const [sharedFolders, setSharedFolders] = useState([]);
  const [duplicatedOriginalName, setDuplicatedOriginalName] = useState("");

  const [showTransferModal, setShowTransferModal] = useState({
    contractFolder: null,
    show: false,
  });

  useEffect(() => {
    dispatch(emptyContractFolder());
    async function findSharedTo() {
      const sharedFolders = await contractFoldersService.findSharedTo(
        user.id,
        "transport"
      );
      setSharedFolders(sharedFolders);
    }
    dispatch(fetchContractFoldersByBroker(user.id, "transport"));
    findSharedTo();
    setLoaderShown(false);
  }, [dispatch, user]);

  const isPrivateKeyValid = useCallback(
    (info) => {
      if (!user.publicKey) {
        return false;
      }

      try {
        decrypt(info, user.publicKey, privateKey);

        return true;
      } catch (err) {
        return false;
      }
    },
    [privateKey, user]
  );
  useEffect(() => {
    if (privateKey !== "" && contractFolders.items.length > 0) {
      const nameToTry = contractFolders.items.filter(
        (cf) => cf.sharedBrokers.length === 0
      )[0]?.name;

      if (!isPrivateKeyValid(nameToTry)) {
        dispatch(
          alertActions.setAlert(t("Incorrect key"), alertConstants.ERROR)
        );
      }
    }
  }, [privateKey, contractFolders, dispatch, t, isPrivateKeyValid]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const shareHandleChange = (e) => {
    setShareData({ ...shareData, [e.target.name]: e.target.value });
  };

  const handleTitleModalClose = () => {
    setTitleModalShown(false);
  };

  const handleDuplicateModalClose = () => {
    setDuplicatedOriginalName("");
    setDuplicateModalShown(false);
  };

  const openTitleModal = () => {
    setTitleModalShown(true);
  };

  const handleShareModalSubmit = async (e) => {
    try {
      setShareModalShown(false);
      setLoaderShown(true);

      const res = await brokersService.findByEmail(shareEmail);

      if (res["hydra:totalItems"] === 1) {
        const shareToUser = res["hydra:member"][0];

        const contractFolderToShare = await contractFoldersService.find(
          currentSharedId
        );

        if (contractFolderToShare.sharedBrokers.length === 0) {
          const decryptedFolderName = decryptInfo(contractFolderToShare.name);
          const folder = {
            id: currentSharedId,
            name: encrypt(decryptedFolderName, user.publicKey, user.publicKey),
          };
          await contractFoldersService.encryptFolder(folder);
          var encryptedFieldValues = [];
          contractFolderToShare.contracts.map((contract) => {
            return contract.fieldValues.map((fieldValue) => {
              const decryptedFieldValue = decryptInfo(fieldValue.value);
              encryptedFieldValues.push({
                id: fieldValue.id,
                value: encrypt(
                  decryptedFieldValue,
                  user.publicKey,
                  user.publicKey
                ),
              });
              return true;
            });
          });

          await contractFieldValuesService.editMultiple(encryptedFieldValues);
        }
        await contractFoldersService.shareContractFolder(
          currentSharedId,
          shareToUser.id
        );
        setShareData({ shareEmail: "" });
        dispatch(fetchContractFoldersByBroker(user.id, "transport"));

        setLoaderShown(false);
        setShareModalShown(false);
      } else {
        dispatch(
          alertActions.setAlert(
            t("This user doesn't exist"),
            alertConstants.ERROR
          )
        );
        setLoaderShown(false);
      }
    } catch (err) {
      dispatch(
        alertActions.setAlert("An error occured : " + err, alertConstants.ERROR)
      );
      setLoaderShown(false);
    }
  };

  const deleteModalOptionSelected = async (option) => {
    if (option === "no") {
      setDeleteModalShown({ status: null, show: false });
    } else if (option === "yes") {
      try {
        await contractFoldersService.remove(currentDeleteId);

        dispatch(
          alertActions.setAlert(
            t("Contract has been removed"),
            alertConstants.SUCCESS
          )
        );

        dispatch(fetchContractFoldersByBroker(user.id, "transport"));
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }

      setDeleteModalShown({ status: null, show: false });
    }
  };
  const handledupicateContract = async (e) => {
    e.preventDefault();

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (!formData.name || formData.name === "") {
        dispatch(
          alertActions.setAlert(t("Name must be filled"), alertConstants.ERROR)
        );
      } else {
        if (formData.name === duplicatedOriginalName) {
          dispatch(
            alertActions.setAlert(
              t("Name must be different"),
              alertConstants.ERROR
            )
          );
        } else {
          const encryptedName = encrypt(
            `${formData.name}`,
            user.publicKey,
            privateKey
          );

          await contractFoldersService.duplicateContractFolder(
            currentDpulicateId,
            encryptedName
          );
          handleDuplicateModalClose();
          setFormData({ name: "" });
          dispatch(fetchContractFoldersByBroker(user.id, "transport"));
          dispatch(
            alertActions.setAlert(
              t("Contract has been duplicated"),
              alertConstants.SUCCESS
            )
          );
        }
      }
    }
  };

  const handleTitleModalSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (privateKey === "") {
      dispatch(
        alertActions.setAlert(
          t("You must set your private key to use this functionnality"),
          alertConstants.ERROR
        )
      );
    } else {
      if (!name || name === "") {
        dispatch(
          alertActions.setAlert(t("Name must be filled"), alertConstants.ERROR)
        );
        return;
      }

      const newContractFolder = {
        name: encrypt(name, user.publicKey, privateKey),
        owner: `/brokers/${user.id}`,
        status: "draft",
        lang: lang,
        folderType: "transport",
      };

      try {
        const addResponse = await contractFoldersService.add(newContractFolder);

        const log = {
          version: 1,
          action: `Creating a new agreement "${name}"`,
          author: user?.id,
          updatedFields: null,
        };

        await contractLogsService?.add(addResponse?.id, log);

        setFormData({ name: "" });
        dispatch(
          alertActions.setAlert(
            t("Contract has been added"),
            alertConstants.SUCCESS
          )
        );
        dispatch(fetchContractFoldersByBroker(user.id, "transport"));
        setTitleModalShown(false);
      } catch (err) {
        dispatch(alertActions.setAlert(err, alertConstants.ERROR));
      }
    }
  };

  const decryptInfo = (info) => {
    if (!user.publicKey) {
      return info;
    }

    try {
      return decrypt(info, user.publicKey, privateKey);
    } catch (err) {
      return info;
    }
  };

  const decryptSharedInfo = (info, sharedFrom) => {
    if (!sharedFrom.publicKey) {
      return info;
    }

    try {
      return decrypt(info, sharedFrom.publicKey, sharedFrom.publicKey);
    } catch (err) {
      return info;
    }
  };

  const [clientAddresses, setClientAddresses] = useState([]);
  const [currentTransferEmail, setCurrentTransferEmail] = useState("");
  const handleFocusOut = (e) => {
    if (e?.currentTarget?.value !== "") {
      setClientAddresses([...clientAddresses, e?.currentTarget?.value]);
      setCurrentTransferEmail("");
    }
  };
  const [transferLoading, setTransferLoading] = useState(false);
  const generatePdfFull = async (contractFolder) => {
    let publicKeyToUse = getPublicKey(user, contractFolder);

    if (contractFolder) {
      let documents = [];
      let codename = "";
      const baseContract =
        contractFolder.folderType === "transport"
          ? contractFolder.contracts.find(
              (c) => c.template.codeName === "baseTemplate"
            )
          : contractFolder.contracts.find(
              (c) => c.template.codeName === "baseTemplateCharter"
            );
      if (baseContract !== undefined) {
        codename = "baseTemplate";
      } else {
        codename = "baseTemplateDay";
      }
      if (!baseContract) {
        const dayContract = contractFolder.contracts.find(
          (c) => c.template.codeName === "baseTemplateDay"
        );

        if (!dayContract) {
          return;
        }
        const baseContractObj = createDocumentObj(
          dayContract,
          contractFolder,
          moment,
          user,
          privateKey,
          t
        );
        documents.push(baseContractObj);
      } else {
        const baseContractObj = createDocumentObj(
          baseContract,
          contractFolder,
          moment,
          user,
          privateKey,
          t
        );

        documents.push(baseContractObj);

        const itineraryContract = contractFolder.contracts.find(
          (c) => c.template.codeName === "itinerary"
        );
        if (itineraryContract) {
          const itineraryContractObj = createDocumentObj(
            itineraryContract,
            contractFolder,
            moment,
            user,
            privateKey,
            t
          );
          documents.push(itineraryContractObj);
        }

        let passengersContract = contractFolder.contracts.find(
          (c) => c.template.codeName === "passengers"
        );

        if (passengersContract) {
          const fieldValues = passengersContract?.fieldValues.filter(
            (fv) => !fv?.field?.hidden
          );
          passengersContract = {
            ...passengersContract,
            fieldValues,
          };
          const passengersContractObj = createDocumentObj(
            passengersContract,
            contractFolder,
            moment,
            user,
            privateKey,
            t
          );
          documents.push(passengersContractObj);
        }

        const riderContract = contractFolder.contracts.find(
          (c) => c.template.codeName === "rider"
        );
        if (riderContract) {
          const riderContractObj = createDocumentObj(
            riderContract,
            contractFolder,
            moment,
            user,
            privateKey,
            t
          );
          documents.push(riderContractObj);
        }
      }

      const legalMentionsDocument = {
        contract:
          contractFolder.lang === "en" ? "legalMentions" : "legalMentionsFr",
        jsonView: null,
      };

      documents.push(legalMentionsDocument);

      const blob = await pdf(
        <Document>
          {documents.map((doc, index) => (
            <ContractDocument
              key={index}
              contract={doc.contract}
              jsonView={doc.jsonView}
              status={contractFolder.status}
              user={user}
              lang={contractFolder.lang}
              codename={codename}
              type={contractFolder.folderType}
            />
          ))}
        </Document>
      ).toBlob();
      const decryptSecondArg =
        contractFolder?.sharedBrokers.length > 0 ||
        contractFolder?.createdFromShared
          ? contractFolder.owner.publicKey
          : publicKeyToUse;

      const decryptThirdArg =
        contractFolder?.sharedBrokers.length > 0 ||
        contractFolder?.createdFromShared
          ? contractFolder.owner.publicKey
          : privateKey;
      var filename = decrypt(
        contractFolder.name,
        decryptSecondArg,
        decryptThirdArg
      );

      filename += ".pdf";
      try {
        setTransferLoading(true);
        await contractsService
          ?.transferContract(
            blob,
            clientAddresses,
            filename,
            contractFolder?.id,
            user?.email
          )
          .then(() => {
            setTransferLoading(false);
            setClientAddresses([]);
            setShowTransferModal({ contractFolder: null, show: false });
            dispatch(
              alertActions.setAlert(
                t("Contract has been transferred"),
                alertConstants.SUCCESS
              )
            );
          });
      } catch (error) {
        setTransferLoading(false);
        dispatch(alertActions.setAlert(t(`${error}`), alertConstants.ERROR));
      }
    }
  };
  const handleTransfer = async (id) => {
    if (id) {
      const contractFolderData = await contractFoldersService.find(id);
      generatePdfFull(contractFolderData);
    }
  };

  const removeClient = (itemToRemove) => {
    const updatedItems = clientAddresses.filter(
      (item) => item !== itemToRemove
    );
    setClientAddresses(updatedItems);
  };

  const [showLogsModal, setShowLogsModal] = useState({
    contractFolder: null,
    show: false,
  });
  const [currentLogs, setCurrentLogs] = useState([]);

  const fetchLogs = useCallback(async () => {
    if (showLogsModal?.show === true) {
      const logs = await contractLogsService?.fetchLogsByFolder(
        showLogsModal?.contractFolder
      );
      setCurrentLogs(logs);
    }
  }, [showLogsModal]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);
  return (
    <>
      <Loader isShown={isLoaderShown} />

      {user.firstConnection && (
        <Redirect to={{ pathname: "/first-connection" }} />
      )}
      <Modal show={isDuplicateModalShown} onHide={handleDuplicateModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("DUPLICATE CONTRACT")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To duplicate a new contract, you can give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handledupicateContract}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleDuplicateModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handledupicateContract}>
            {t("Duplicate")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isTitleModalShown} onHide={handleTitleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "To add a new contract, you must give it a name. It would be used as a reference, and never shown to your client"
            )}
          </p>

          <Form onSubmit={handleTitleModalSubmit}>
            <Form.Group controlId="contractName">
              <Form.Label>{t("Contract's name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Contract's name")}
                name="name"
                value={name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="contractLang">
              <Form.Label>{t("Contract's lang")}</Form.Label>
              <Form.Control
                as="select"
                name="lang"
                value={lang}
                onChange={handleChange}
              >
                <option value="en">EN</option>
                <option value="fr">FR</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleTitleModalClose}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleTitleModalSubmit}>
            {t("Add")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isShareModalShown} onHide={() => setShareModalShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Share contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "You want to share a contract with another user of the platform. Please fill his email"
            )}
          </p>

          <Form onSubmit={handleShareModalSubmit}>
            <Form.Group controlId="userEmail">
              <Form.Label>{t("User's email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("User's email")}
                name="shareEmail"
                value={shareEmail}
                onChange={shareHandleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShareModalShown(false)}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleShareModalSubmit}>
            {t("Share")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isShareModalShown} onHide={() => setShareModalShown(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Share contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t(
              "You want to share a contract with another user of the platform. Please fill his email"
            )}
          </p>

          <Form onSubmit={handleShareModalSubmit}>
            <Form.Group controlId="userEmail">
              <Form.Label>{t("User's email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("User's email")}
                name="shareEmail"
                value={shareEmail}
                onChange={shareHandleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShareModalShown(false)}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={handleShareModalSubmit}>
            {t("Share")}
          </Button>
        </Modal.Footer>
      </Modal>

      {isDeleteModalShown.show && (
        /* (isDeleteModalShown.status === "draft" ? (*/
        <ValidationModal
          title={t("Delete contract")}
          text={
            "<p>" +
            t("WARNING ! This action cannot be undone.") +
            "</p>" +
            "<p>" +
            t("Do you wish to continue ?") +
            "</p>"
          }
          show={isDeleteModalShown.show}
          onOptionSelected={deleteModalOptionSelected}
        />
      )}

      <Modal
        show={showTransferModal?.show}
        onHide={() =>
          setShowTransferModal({ contractFolder: null, show: false })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Transfer contract")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="userEmail">
              <Form.Label>
                {t("Enter one or multiple email addresses")}
              </Form.Label>
              <Form.Control
                type="email"
                disabled={transferLoading}
                name="transferEmails"
                onBlur={handleFocusOut}
                value={currentTransferEmail}
                onChange={(e) => setCurrentTransferEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
          <div className="d-flex flex-column">
            {clientAddresses?.map((address) => {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <span className="mx-1">{address}</span>
                  <i
                    key={address}
                    class="fa fa-trash"
                    role="button"
                    style={{ color: "#203f80" }}
                    onClick={() => removeClient(address)}
                  ></i>{" "}
                </div>
              );
            })}
          </div>
          {transferLoading && (
            <div className="d-flex align-items-center">
              <Spinner animation="border" variant="primary" className="mx-2" />
              <div className="d-flex flex-column" style={{ color: "#203f80" }}>
                <span>{t("Contract is being transferred.")}</span>
                <span>{t("Please wait a few moments.")}</span>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShareModalShown(false)}>
            {t("Cancel")}
          </Button>

          <Button
            variant="primary"
            onClick={() => handleTransfer(showTransferModal?.contractFolder)}
            disabled={transferLoading}
          >
            {t("Transfer")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showLogsModal?.show}
        onHide={() => {
          setShowLogsModal({ contractFolder: null, show: false });
          setCurrentLogs([]);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Historique")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Table bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr
                style={{
                  textAlign: "center",
                  backgroundColor: "#b3b2b2",
                  color: "#FFFFFF",
                }}
              >
                <th>{t("Version")}</th>
                <th>{t("Date")}</th>
                <th>{t("Action")}</th>
                <th>{t("Author")}</th>
                <th>{t("Specific details")}</th>
              </tr>
            </thead>
            <tbody>
              {currentLogs?.map((log) => {
                return (
                  <tr role="button">
                    <td>{log?.version}</td>
                    <td>
                      {moment(log?.log_date).format("DD/MM/yyyy HH:mm:ss")}
                    </td>
                    <td>{log?.action}</td>
                    <td>{log?.author}</td>
                    {log?.updatedFields ? (
                      <td className="d-flex flex-column">
                        {log?.updatedFields?.map((field) => {
                          return (
                            <span>
                              Change <b>{field?.fieldName}</b> value to{" "}
                              <b>"{field?.fieldValue}"</b>
                            </span>
                          );
                        })}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
      <PrivateKey />

      {privateKey && sharedFolders && contractFolders && (
        <Row>
          <Col md={12}>
            <h1 className="page-title">
              {t("Your agreements")}
              {user?.publicKey &&
                ((contractFolders.items.some(
                  (cf) => cf.sharedBrokers.length === 0
                ) &&
                  isPrivateKeyValid(
                    contractFolders.items.filter(
                      (cf) => cf.sharedBrokers.length === 0
                    )[0]?.name
                  )) ||
                  !contractFolders.items.some(
                    (cf) => cf.sharedBrokers.length === 0
                  )) &&
                privateKey &&
                privateKey !== "" &&
                (user?.company?.availableTokens === -1 ||
                  user?.company?.availableTokens > 0) && (
                  <Button
                    variant="success"
                    size="sm"
                    style={{
                      marginLeft: "15px",
                      padding: "2px 10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    onClick={openTitleModal}
                  >
                    + {t("New")}
                  </Button>
                )}
            </h1>

            <Table bordered hover style={{ textAlign: "center" }}>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    backgroundColor: "#b3b2b2",
                    color: "#FFFFFF",
                  }}
                >
                  <th>{t("Name")}</th>

                  <th>{t("Status")}</th>

                  <th>{t("Lang")}</th>

                  <th>{t("Version")}</th>

                  <th>{t("Shared with")}</th>

                  <th>{t("Actions")}</th>
                </tr>
              </thead>

              <tbody>
                {user?.publicKey &&
                  privateKey &&
                  privateKey !== "" &&
                  contractFolders?.items.map((contractFolder, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {contractFolder.sharedBrokers.length > 0 ||
                          contractFolder.createdFromShared
                            ? decryptSharedInfo(contractFolder.name, user)
                            : decryptInfo(contractFolder.name)}
                        </td>

                        <td>
                          {contractFolder.status === "draft" && (
                            <span style={{ color: "#e9725b" }}>
                              {t("Draft")}
                            </span>
                          )}
                          {contractFolder.status === "contract" && (
                            <span style={{ color: "#218838" }}>
                              {t("Contract")}
                            </span>
                          )}
                        </td>

                        <td>
                          {contractFolder.lang ? contractFolder.lang : "en"}
                        </td>

                        <td>{contractFolder.version}</td>

                        <td>
                          {contractFolder?.sharedBrokers.map(
                            (sharedContract) => (
                              <React.Fragment key={sharedContract.id}>
                                {sharedContract.email}

                                {(contractFolder.sharedBrokers.length > 0 ||
                                  contractFolder.createdFromShared ||
                                  isPrivateKeyValid(contractFolder.name)) && (
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    style={{ marginLeft: "10px" }}
                                    onClick={async () => {
                                      setLoaderShown(true);
                                      if (
                                        contractFolder.sharedBrokers.length ===
                                        1
                                      ) {
                                        const decryptedFolderName =
                                          decryptSharedInfo(
                                            contractFolder.name,
                                            user
                                          );

                                        const folder = {
                                          id: contractFolder.id,
                                          name: encrypt(
                                            decryptedFolderName,
                                            user.publicKey,
                                            privateKey
                                          ),
                                        };
                                        await contractFoldersService.encryptFolder(
                                          folder
                                        );
                                        if (
                                          contractFolder.fieldValues.length >
                                            0 &&
                                          !contractFolder?.createdFromShared
                                        ) {
                                          var encryptedFieldValues = [];

                                          contractFolder.fieldValues.map(
                                            (fieldValue) => {
                                              const decryptedFieldValue =
                                                decryptSharedInfo(
                                                  fieldValue.value,
                                                  user
                                                );

                                              encryptedFieldValues.push({
                                                id: fieldValue.id,
                                                value: encrypt(
                                                  decryptedFieldValue,
                                                  user.publicKey,
                                                  privateKey
                                                ),
                                              });

                                              return true;
                                            }
                                          );
                                          await contractFieldValuesService.editMultiple(
                                            encryptedFieldValues
                                          );
                                        }
                                      }

                                      await contractFoldersService.refreshSharedInstancesContractFolder(
                                        contractFolder.id,
                                        sharedContract.id
                                      );
                                      setLoaderShown(false);
                                      dispatch(
                                        fetchContractFoldersByBroker(
                                          user.id,
                                          "transport"
                                        )
                                      );
                                    }}
                                    title={t("Delete")}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                )}

                                <br />
                              </React.Fragment>
                            )
                          )}
                        </td>

                        <td>
                          {(contractFolder.sharedBrokers.length > 0 ||
                            contractFolder.createdFromShared ||
                            isPrivateKeyValid(contractFolder.name)) && (
                            <>
                              <Link
                                to={`/contracts/folders/${contractFolder.id}`}
                              >
                                <OverlayTrigger
                                  key="see"
                                  placement="top"
                                  overlay={
                                    <Tooltip id="see">
                                      {t("See / Modify")}
                                    </Tooltip>
                                  }
                                >
                                  <Button variant="outline-primary" size="sm">
                                    <i className="fa fa-list-alt"></i>
                                  </Button>
                                </OverlayTrigger>
                              </Link>

                              <OverlayTrigger
                                key="share"
                                placement="top"
                                overlay={
                                  <Tooltip id="share">{t("Share")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setCurrentSharedId(contractFolder.id);
                                    setShareModalShown(true);
                                  }}
                                >
                                  <i className="fas fa-share-square"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="delete"
                                placement="top"
                                overlay={
                                  <Tooltip id="delete">{t("Delete")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setCurrentDeleteId(contractFolder.id);
                                    setDeleteModalShown({
                                      status: contractFolder.status,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="dupliquer"
                                placement="top"
                                overlay={
                                  <Tooltip id="dupliquer">
                                    {t("Duplicate")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setcurrentDpulicateId(contractFolder.id);
                                    const originalName = decryptInfo(
                                      contractFolder.name
                                    );
                                    setDuplicatedOriginalName(originalName);
                                    setDuplicateModalShown(true);
                                  }}
                                >
                                  <i className="fa fa-clone"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="transfer"
                                placement="top"
                                overlay={
                                  <Tooltip id="transfer">
                                    {t("Transfer")}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setShowTransferModal({
                                      contractFolder: contractFolder?.id,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i class="fa fa-paper-plane"></i>{" "}
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                key="history"
                                placement="top"
                                overlay={
                                  <Tooltip id="history">{t("History")}</Tooltip>
                                }
                              >
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    setShowLogsModal({
                                      contractFolder: contractFolder?.id,
                                      show: true,
                                    });
                                  }}
                                >
                                  <i class="fa fa-history"></i>{" "}
                                </Button>
                              </OverlayTrigger>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>

            {sharedFolders?.length > 0 && (
              <>
                <h2 className="page-title">{t("Shared contracts")}</h2>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>

                      <th>{t("Shared by")}</th>

                      <th>{t("Status")}</th>

                      <th>{t("Lang")}</th>

                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {user?.publicKey &&
                      privateKey &&
                      privateKey !== "" &&
                      sharedFolders?.map((contractFolder, index) => (
                        <tr key={index}>
                          <td>
                            {decryptSharedInfo(
                              contractFolder.name,
                              contractFolder.owner
                            )}
                          </td>

                          <td>
                            {contractFolder.owner.name}{" "}
                            {contractFolder.owner.firstname}
                          </td>

                          <td>
                            {contractFolder.status === "draft" && (
                              <Badge pill variant="warning">
                                {t("Draft")}
                              </Badge>
                            )}
                            {contractFolder.status === "contract" && (
                              <Badge pill variant="success">
                                {t("Contract")}
                              </Badge>
                            )}
                          </td>

                          <td>
                            {contractFolder.lang ? contractFolder.lang : "en"}
                          </td>

                          <td>
                            <Link
                              to={`/contracts/folders/${contractFolder.id}`}
                            >
                              <Button variant="primary" size="sm">
                                <i className="fa fa-eye"></i>
                              </Button>
                            </Link>

                            <Button
                              variant="danger"
                              size="sm"
                              style={{ marginLeft: "10px" }}
                              onClick={async () => {
                                await contractFoldersService.refreshSharedInstancesContractFolder(
                                  contractFolder.id,
                                  user.id
                                );
                                dispatch(
                                  fetchContractFoldersByBroker(
                                    user.id,
                                    "transport"
                                  )
                                );
                              }}
                              title={t("Delete")}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
